import { render } from "./Register.vue?vue&type=template&id=7bf3755a&scoped=true"
import script from "./Register.vue?vue&type=script&lang=js"
export * from "./Register.vue?vue&type=script&lang=js"

import "./Register.vue?vue&type=style&index=0&id=7bf3755a&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-7bf3755a"
/* hot reload */
if (module.hot) {
  script.__hmrId = "7bf3755a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7bf3755a', script)) {
    api.reload('7bf3755a', script)
  }
  
  module.hot.accept("./Register.vue?vue&type=template&id=7bf3755a&scoped=true", () => {
    api.rerender('7bf3755a', render)
  })

}

script.__file = "src/components/Register.vue"

export default script