<template>
  <div id="delete">
    <div v-if="story.id > 0" class="item button" @click="deleteStory">
      <span class="more uppercase nav nav_go nav_go_s nav_go_right "
        >löschen</span
      >
    </div>
  </div>
  <AdminLang v-model="en" :en="en" />
  <div v-if="!isLoading && small" class="preview p">
    <!--  <Event :event="event" />-->
  </div>
  <div class="admin_center">
    <!--  :class="{ p: !small }" -->
    <div v-if="!isLoading" id="editEvent" class="edit">
      <div>
        <div class="item">
          <form class="e">
            <div>
              <BaseInput
                v-if="en"
                v-model="story.title_en"
                label="Titel (en)"
                type="text"
              />
              <BaseInput
                v-else
                v-model="story.title"
                label="Titel*"
                type="text"
              />
              <BaseTextarea
                v-if="en"
                v-model="story.text_en"
                label="Text (en)"
              />
              <BaseTextarea v-else v-model="story.text" label="Text*" />
            </div>
            <div>
              <BaseInput v-model="story.year" label="Datum*" type="date" />
              <label for="house">Klinik*</label>
              <select v-model="story.house" class="dropdown" name="amount">
                <option value="1">Lukas</option>
                <option value="2">Wegman</option>
                <option value="3">Arlesheim</option>
              </select>

              <label> Im Programm anzeigen </label>
              <select v-model="story.month" class="dropdown" name="amount">
                <option value="0" default>Nicht anzeigen</option>
                <option value="1">Januar</option>
                <option value="2">Februar</option>
                <option value="3">März</option>
                <option value="4">April</option>
                <option value="5">Mai</option>
                <option value="6">Juni</option>
                <option value="7">Juli</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">Otober</option>
                <option value="11">November</option>
                <option value="12">Dezember</option>
              </select>
              <BaseCheckbox
                v-model="story.featured"
                label="Auf Startseite anzeigen"
              />
            </div>
          </form>
        </div>
        <div
          class="item button"
          :class="{ mainb: !imageSet }"
          @click="openImgLayer(false, $event)"
        >
          <span class="more uppercase nav nav_go nav_go_s nav_go_right "
            >Hintergrundbild {{ imageSet ? "ändern" : "hinzufügen" }}</span
          >
        </div>
        <div class="item button" @click="openImgLayer(true, $event)">
          <span class="more uppercase nav nav_go nav_go_s nav_go_right "
            >Bild zur Serie hinzufügen</span
          >
        </div>
        <div class="item button" @click="openContentLayer(true, $event)">
          <span class="more uppercase nav nav_go nav_go_s nav_go_right "
            >Inhalt erstellen (PDF/Video)</span
          >
        </div>
        <div
          class="item button"
          :class="{ mainb: imageSet }"
          @click="checkForm"
        >
          <span class="more uppercase nav nav_go nav_go_s nav_go_right "
            >Speichern</span
          >
        </div>
        <div v-if="success" class="success item">
          <span class="small">
            {{ notifications.success[0] }}
          </span>
        </div>
        <div v-else class="error item">
          <span
            v-for="(e, key) in notifications.errors"
            :key="key"
            class="small"
          >
            {{ e }}
          </span>
        </div>
      </div>
    </div>
    <div>
      <div>
        <!--  <div v-if="!isLoading && !small" class="preview">
                <Story :story="story" />
        </div> -->
        <div v-if="!isLoading" class="c_container">
          <div class="item">
            <span class="small">Verknüfpte Bilder</span>
            <div class="c">
              <img
                v-for="i in images"
                :key="i.id"
                class="select"
                :src="
                  $store.state.connection +
                    '/storage/images/' +
                    i.link_name +
                    '_200.jpg'
                "
                @click="removeImage(i.id)"
              />
            </div>
          </div>
          <div class="item in">
            <span class="small">Verknüpfte Inhalte</span>
            <div class="c">
              <div v-for="cont in story.content" :key="cont.id" class="c_item">
                <span class="big"> {{ cont.title }} </span>
                <span class="small">
                  <a
                    v-if="cont.type == 1"
                    :href="
                      $store.state.connection +
                        '/storage/files/' +
                        cont.link_name +
                        '.pdf'
                    "
                    target="_blank"
                    >pdf</a
                  >
                  <a
                    v-else-if="cont.type == 2"
                    :href="'https://youtube.com/watch?v=' + cont.link_name"
                    target="_blank"
                    >video</a
                  >
                </span>
                <p>{{ cont.text }}</p>
                <div class="options">
                  <span
                    class="more uppercase nav nav_go nav_go_s nav_go_right add"
                    @click="disconnectContent(cont)"
                    >Entfernen</span
                  >
                  <span
                    class="more uppercase nav nav_go nav_go_s nav_go_right delete"
                    @click="editContent(cont)"
                    >Bearbeiten</span
                  >
                </div>
                <!--  <Contributor :key="contributor.id" :contributor="contributor" />-->
              </div>
            </div>
          </div>
          <div class="item out">
            <span class="small">Verfügbare Inhalte</span>
            <div class="c">
              <div v-for="cont in content" :key="cont.id" class=" c_item">
                <span class="big"> {{ en ? cont.title_en : cont.title }} </span>
                <span class="small">
                  <a
                    v-if="
                      cont.type == 1 &&
                        ((en && cont.link_name_en != '') ||
                          (!en && cont.link_name != ''))
                    "
                    :href="
                      $store.state.connection +
                        '/storage/files/' +
                        (en ? cont.link_name_en : cont.link_name) +
                        '.pdf'
                    "
                    target="_blank"
                    >pdf</a
                  >
                  <a
                    v-else-if="cont.type == 2"
                    :href="'https://youtube.com/watch?v=' + cont.link_name"
                    target="_blank"
                    >video</a
                  >
                </span>
                <p>{{ en ? cont.text_en : cont.text }}</p>
                <div class="options">
                  <span
                    class="more uppercase nav nav_go nav_go_s nav_go_right"
                    @click="connectContent(cont)"
                    >Hinzufügen</span
                  >
                  <span
                    class="more uppercase nav nav_go nav_go_s nav_go_right delete"
                    @click="editContent(cont)"
                    >Bearbeiten</span
                  >
                  <!--     <span
                    class="more uppercase nav nav_go nav_go_s nav_go_right delete"
                    @click="deleteContent(cont.id)"
                    >Löschen</span
                  >-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <ChooseImage
      v-if="selectingImage"
      :type="imgType"
      @set-image="setImage($event)"
      @close="closeImgLayer"
    />
    <ChooseContent
      v-if="addContent"
      :c="c"
      @set-content="setContent($event)"
      @close="closeContentLayer"
    />
    <ChooseContent
      v-if="changeContent"
      :c="contentToChange"
      :edit="true"
      :en="en"
      @set-content="setContent($event)"
      @close="closeContentLayer"
      @remove="removeContent($event)"
    />
  </div>
</template>

<script>
import axios from "axios";
import BaseInput from "../components/BaseInput";
import BaseTextarea from "../components/BaseTextarea";
import BaseCheckbox from "../components/BaseCheckbox";
import ChooseImage from "../components/ChooseImage";
import ChooseContent from "../components/ChooseContent";
import AdminLang from "../components/AdminLang";

export default {
  components: {
    BaseInput,
    BaseTextarea,
    ChooseImage,
    ChooseContent,
    BaseCheckbox,
    AdminLang
  },
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      story: {
        id: 0,
        title: "",
        text: "",
        title_en: "",
        text_en: "",
        year: "",
        images: [],
        image: {},
        content: [],
        image_id: 0,
        house: 0,
        month: 0
      },
      content: [],
      multiday: false,
      isLoading: true,
      notifications: { errors: [], success: [] },
      selectingImage: false,
      addContent: false,
      selectMultiImage: false,
      changeContent: false,
      contentToChange: null,
      image: 0,
      imgType: 1,
      en: false,
      c: {
        id: 0,
        type: 0,
        title: "",
        text: "",
        title_en: "",
        text_en: "",
        link_name: "",
        link_name_en: ""
      }
    };
  },
  computed: {
    hasImg: {
      get: function() {
        return this.story.image_id == 1;
      },
      // setter
      set: function(bool) {
        this.story.image_id = bool ? 1 : 0;
      }
    },
    imageSet() {
      return this.story.image_id > 0;
    },
    small() {
      return this.$store.getters.break1;
    },
    images() {
      return this.uniqueImages(this.story.images, "id");
    },
    error() {
      return this.notifications.errors.length > 0;
    },
    success() {
      return this.notifications.success.length > 0;
    }
  },
  created() {
    if (this.id === 0) {
      this.isLoading = false;
    } else {
      this.getStory();
    }
    this.getContent();
  },
  methods: {
    getStory() {
      if (this.id == 0) {
        this.isLoading = false;
      } else {
        axios
          .get(this.$store.state.connection + "/api/getStoryById?id=" + this.id)
          .then(({ data }) => {
            this.story = data;

            this.isLoading = false;
          });
      }
    },
    getContent() {
      axios
        .get(this.$store.state.connection + "/api/getContent")
        .then(({ data }) => {
          this.content = data;
        });
    },
    uniqueImages(_arr, prop) {
      return _arr.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
      });
    },
    setImage(e) {
      //  let image = { link_name: e.link, id: e.id };
      if (this.selectMultiImage) {
        let image = { link_name: e.link, id: e.id };
        this.story.images.push(image);
      } else {
        this.story.image_id = e.id;
        let image = { link_name: e.link };
        this.story.image = image;
      }
      this.selectingImage = false;
    },
    setContent(e) {
      //  let image = { link_name: e.link, id: e.id };
      this.connectContent(e);
      this.addContent = false;
    },
    removeImage(remove) {
      for (var i = 0; i < this.story.images.length; i++) {
        if (this.story.images[i].id == remove) {
          this.story.images.splice(i, 1);
        }
      }
    },
    openImgLayer(multi, e) {
      e.preventDefault();
      this.selectMultiImage = multi;
      this.selectingImage = true;
    },
    editContent(cont) {
      this.contentToChange = cont;
      this.changeContent = true;
    },
    openContentLayer(multi, e) {
      e.preventDefault();
      this.addContent = true;
    },
    closeContentLayer() {
      this.addContent = false;
      this.changeContent = false;
      this.contentToChange = null;
    },
    closeImgLayer() {
      this.selectingImage = false;
    },
    checkForm(e) {
      e.preventDefault();
      this.notifications.errors = [];
      //TODO: validate
      if (
        !this.story.title ||
        !this.story.text ||
        !this.story.year ||
        !this.story.house
      ) {
        this.notifications.errors.push("Bitte alle Felder ausfüllen");
      } else {
        if (this.story.id > 0) {
          this.changeStory();
        } else {
          this.addStory();
        }
      }
    },
    removeContent(id) {
      let c = this.content.find(c => c.id === id);
      let index = this.content.indexOf(c);
      this.content.splice(index, 1);
    },
    changeStory() {
      this.story.images = this.orderImages(this.images);
      this.notifications.errors = [];
      this.notifications.success = [];
      this.$store
        .dispatch("changeStory", this.story)
        .then(() => {
          //   this.$router.push({ name: "manageEvents" });
          this.notifications.success.push("Änderung gespeichert");
        })
        .catch(err => {
          if (err.response.status === 401) {
            this.notifications.errors = [];
            this.notifications.errors.push("keine Berechtigung");
          } else {
            this.notifications.errors.push(err.error);
          }
        });
    },
    addStory() {
      const self = this;
      this.story.images = this.images;
      this.$store
        .dispatch("addStory", this.story)
        .then(res => {
          self.story.id = res;
          //   this.$router.push({ name: "managestorys" });
          this.notifications.success.push("Eintrag erstellt");
        })
        .catch(err => {
          if (err.response.status === 401) {
            this.notifications.errors = [];
            this.notifications.errors.push("keine Berechtigung");
          } else {
            this.notifications.errors.push(err.error);
          }
        });
    },
    orderImages(images) {
      return images;
      //images.forEach(function(image))
    },
    deleteStory(e) {
      e.preventDefault();
      this.$store
        .dispatch("deleteStory", {
          id: this.story.id
        })
        .then(() => {
          this.$router.push({
            name: "manageStory"
          });
        })
        .catch(err => {
          if (err.response.status === 401) {
            this.notifications.errors = [];
            this.notifications.errors.push("keine Berechtigung");
          } else {
            this.notifications.errors.push(err.error);
          }
        });
    },
    connectContent(content) {
      this.story.content.push(content);
      let index = this.content.findIndex(c => c.id === content.id);
      this.content.splice(index, 1);
      //c.active = true;
    },
    disconnectContent(content) {
      let index = this.story.content.findIndex(c => c.id === content.id);
      this.story.content.splice(index, 1);
      if (!this.content.find(c => c.id === content.id)) {
        this.content.push(content);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/_variables.scss";
@import "../scss/_admin.scss";

.admin_center {
  justify-content: left;
}
form.e {
  min-width: 39rem;
  width: 33vw;
}
.c_container {
  display: flex;
  flex-direction: column;
}
.c {
  display: flex;
  flex-wrap: wrap;
  min-height: 1rem !important;
}
.in {
  background: #b9b955;
}
.out {
}
.c_item {
  width: 30rem;
  min-height: 10rem;
  border: 1px solid white;
  padding: 2rem;
}
.c_item > .container {
  width: 100%;
}
.preview {
  padding: 1rem;
}
.c_item > span > a {
  text-decoration: underline;
}
.c_item > .options > .more {
  margin-top: 1rem;
  margin-bottom: 0rem;
  color: white;
}
.c_item > .options > .more::before {
  background: white;
}
.c_item > .options > .delete,
.c_item > .options > .add {
  color: #0a268a;
}
.c_item > .options > .delete::before,
.c_item > .options > .add:before {
  background: #0a268a;
}
.c_item {
}
</style>
