/* eslint no-multiple-template-root: 0 */
<template>
  <label> {{ label }} </label>
  <input
    v-bind="$attrs"
    :value="modelValue"
    class="field"
    @input="$emit('update:modelValue', $event.target.value)"
  />
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    modelValue: {
      type: [String, Number, Date],
      default: ""
    }
  },
  emits: ["update:modelValue"]
};
</script>
<style lang="scss" scoped>
@import "../scss/_variables.scss";
@import "../scss/_admin.scss";
label {
  font-size: 2rem;
}

.field {
  color: $mainBlue;
}
</style>
