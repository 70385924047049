import { render } from "./Contributor.vue?vue&type=template&id=124db95b&scoped=true"
import script from "./Contributor.vue?vue&type=script&lang=js"
export * from "./Contributor.vue?vue&type=script&lang=js"

import "./Contributor.vue?vue&type=style&index=0&id=124db95b&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-124db95b"
/* hot reload */
if (module.hot) {
  script.__hmrId = "124db95b"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('124db95b', script)) {
    api.reload('124db95b', script)
  }
  
  module.hot.accept("./Contributor.vue?vue&type=template&id=124db95b&scoped=true", () => {
    api.rerender('124db95b', render)
  })

}

script.__file = "src/components/Contributor.vue"

export default script