import { render } from "./PasswordReset.vue?vue&type=template&id=2d7d6cac&scoped=true"
import script from "./PasswordReset.vue?vue&type=script&lang=js"
export * from "./PasswordReset.vue?vue&type=script&lang=js"

import "./PasswordReset.vue?vue&type=style&index=0&id=2d7d6cac&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-2d7d6cac"
/* hot reload */
if (module.hot) {
  script.__hmrId = "2d7d6cac"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('2d7d6cac', script)) {
    api.reload('2d7d6cac', script)
  }
  
  module.hot.accept("./PasswordReset.vue?vue&type=template&id=2d7d6cac&scoped=true", () => {
    api.rerender('2d7d6cac', render)
  })

}

script.__file = "src/views/PasswordReset.vue"

export default script