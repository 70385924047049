<template>
  <div class="admin_center p">
    <div>
      <div class="item">
        <form>
          <label for="email">
            E-Mail:
          </label>
          <input v-model="email" type="email" name="email" value />

          <label for="password">
            Passwort:
          </label>
          <input v-model="password" type="password" name="password" value />
        </form>
      </div>
      <div class="item button mainb" @click="login">
        <span class="more uppercase nav nav_go nav_go_s nav_go_right"
          >Anmelden</span
        >
      </div>
      <router-link v-if="!$store.getters.resetEmail" to="/reset-request">
        <span class="small"> Passwort vergessen? </span>
      </router-link>
      <div class="error item">
        <span v-for="(e, key) in error" :key="key" class="small">
          {{ e }}
        </span>
      </div>
    </div>

    <div>
      <span v-if="$store.getters.resetEmail" class="small">
        Ein Link zum Ändern des Passworts wurde an
        {{ $store.getters.resetEmail }} gesendet.
      </span>
      <router-link v-if="$store.getters.resetEmail" to="/reset-request">
        <span class="small"> erneut senden </span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      error: []
    };
  },
  methods: {
    login() {
      this.error = [];
      this.$store
        .dispatch("login", {
          email: this.email,
          password: this.password
        })
        .then(() => {
          this.$router.push({ name: "manageEvents" });
        })
        .catch(() => {
          this.error.push("E-Mail oder Passwort falsch");
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/_variables.scss";
@import "../scss/_admin.scss";
.admin_center {
  align-items: center;
}
.login {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5rem;
  color: white;
}
.login {
}
input {
  width: 30rem;
}

.more {
  margin: 1rem 0;
}
span.small {
  padding-left: 3rem;
  color: white;
}
</style>
