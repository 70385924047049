<template>
  <router-link
    :to="{
      name: 'jahr',
      params: { webid: story.web_id, id: story.id, odd: odd }
    }"
  >
    <div class="toStoryText">
      <div class="block">
        <span class="big">
          {{ date }}
        </span>

        <span class="more mobile uppercase nav nav_go nav_go_s nav_go_right"
          >Zur Geschichte</span
        >
      </div>
    </div>
    <div
      class="toStory"
      :style="{ 'background-image': 'url(' + image + ')' }"
    ></div>
  </router-link>
</template>

<script>
import moment from "moment";

export default {
  name: "LinkToStory",
  props: {
    story: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  computed: {
    date() {
      // return moment(this.story.year, "YYYY-MM-DD").format("MMMM YYYY");
      return moment(this.story.year, "YYYY-MM-DD").format("YYYY");
    },
    image() {
      if (this.story.image != null) {
        return (
          this.$store.state.connection +
          "/storage/images/" +
          this.story.image.link_name +
          "_200.jpg"
        );
      }
      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/_variables.scss";
.toStory {
  position: absolute;
  top: 0;
  right: 0;
  width: 60rem;
  height: 29.2rem;
  transform: translate(47.5rem, -1.5rem);
  transition: 0.1s ease-in-out;
  z-index: 0;
  background-repeat: no-repeat;
  background-size: cover;
  mask: url("../assets/Mask/maske_bild_jahrhighlight_programm.svg");
  mask-repeat: no-repeat;
}

.toStoryText {
  position: absolute;
  display: flex;
  color: white;
  top: 29.2rem;
  right: 0;
  width: 42rem;
  height: 21.8rem;
  justify-content: flex-end;
  //  background: red;
}
.block > .nav_go::before {
  background: white;
}
.block > .nav_go {
  margin-top: 1rem;
}
.toStoryText:hover + .toStory {
  transform: translate(0rem, -1.5rem);
  transition: 0.5s ease-in-out;
}
.toStory:hover {
  transform: translate(0rem, -1.5rem);
  transition: 0.5s ease-in-out;
}
.block {
  text-align: left;
}
</style>
