<template>
  <div id="new">
    <div class="item button " @click="editContributor(0)">
      <span class="more uppercase nav nav_go nav_go_s nav_go_right"
        >neue Referent*in erstellen</span
      >
    </div>
  </div>
  <div class="admin_center">
    <div
      v-for="contributor in contributors"
      :key="contributor.id"
      class="item c click"
      @click="editContributor(contributor.id)"
    >
      <Contributor :key="contributor.id" :contributor="contributor" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Contributor from "../components/Contributor";

export default {
  components: {
    Contributor
  },
  data() {
    return {
      isLoading: true,
      contributors: []
    };
  },
  created() {
    axios
      .get(this.$store.state.connection + "/api/getContributors")
      .then(({ data }) => {
        this.contributors = data;
        this.isLoading = false;
      });
  },
  methods: {
    editContributor: function(id) {
      this.$router.push({
        name: "editContributor",
        params: {
          id: id
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../scss/_variables.scss";
@import "../scss/admin.scss";
.new > span {
  color: white;
}
.admin_center {
  flex-wrap: wrap;
  justify-content: flex-start;
}

.nav_go::before {
  /* background: $mainBlue;*/
}
</style>
