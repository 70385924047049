<template>
  <div v-if="ready" class="page" :class="{ mobile: mobile }">
    <div
      v-for="(s, key) in story"
      :key="key"
      class="bg"
      :class="{
        bg1: s.house == 1,
        bg2: s.house == 2,
        bg3: s.house == 3,
        reverse: !isOdd(key),
        first: key == 0
      }"
    >
      <div :id="getDecade(s.year)" class="anchor"></div>
      <StoryPoint :story="s" :oddk="key" :first="key == 0" />
    </div>
  </div>
</template>

<script>
//import axios from "axios";
import StoryPoint from "../components/StoryPoint";
import moment from "moment";

export default {
  components: { StoryPoint },
  data() {
    return {
      decade: Number
    };
  },
  computed: {
    mobile() {
      return this.$store.getters.mobile;
    },
    small() {
      return this.$store.getters.break1;
    },
    loading() {
      return this.$store.getters.storyLoading;
    },
    ready() {
      return this.$store.getters.storyLoaded;
    },
    story() {
      return this.$store.getters["story"];
    },
    decades() {
      return this.$store.getters.decades;
    },
    loggedIn() {
      return this.$store.getters["loggedIn"];
    },
    en() {
      return this.$store.getters.en;
    }
  },
  created() {
    if ((!this.ready && !this.loading) || this.loggedIn) {
      const self = this;
      this.$store.dispatch("loadStory").then(() => {
        if (this.$route.hash) {
          setTimeout(() => self.scrollTo(self.$route.hash), 1);
        }
      });
    }
  },
  mounted() {
    const self = this;
    if (this.$route.hash && this.ready) {
      setTimeout(() => this.scrollTo(self.$route.hash), 1);
    }
  },
  methods: {
    getDecade(year) {
      year = moment(year, "YYYY-MM-DD").format("YYYY");
      return ((year / 10) ^ 0) * 10;
      //  return this.decades.filter(d => d >= year)[0];
    },
    isOdd(x) {
      return x % 2;
    },
    scrollTo: function(hashtag) {
      location.hash = "";
      setTimeout(() => {
        location.hash = hashtag;
      }, 10);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/_variables.scss";
.bg {
  position: relative;
  overflow: hidden;
}

.first {
  padding-top: 7rem;
}
.page.mobile {
  min-height: calc(100vh - 7.5rem);
}
</style>
