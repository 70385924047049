<template>
  <label> {{ label }} </label>
  <textarea
    :value="modelValue"
    v-bind="$attrs"
    @input="$emit('update:modelValue', $event.target.value)"
  />
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    modelValue: {
      type: [String],
      default: ""
    }
  },
  emits: ["update:modelValue"]
};
</script>
<style scoped>
textarea {
  /*
  outline: none;*/
  font-size: 2rem;
  resize: none;
  width: 100%;
  /*  max-width: 39rem; */
  width: calc(100% - 1rem);
  height: 20rem;
  border-width: 2px;
  border-color: #0a268a;
  color: #0a268a;
  background: none;
}
</style>
