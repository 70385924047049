import { render } from "./Event.vue?vue&type=template&id=55555bd0&scoped=true"
import script from "./Event.vue?vue&type=script&lang=js"
export * from "./Event.vue?vue&type=script&lang=js"

import "./Event.vue?vue&type=style&index=0&id=55555bd0&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-55555bd0"
/* hot reload */
if (module.hot) {
  script.__hmrId = "55555bd0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('55555bd0', script)) {
    api.reload('55555bd0', script)
  }
  
  module.hot.accept("./Event.vue?vue&type=template&id=55555bd0&scoped=true", () => {
    api.rerender('55555bd0', render)
  })

}

script.__file = "src/views/Event.vue"

export default script