<template>
  <div id="close">
    <div class="item button" @click="close">
      <span class="more uppercase nav nav_go nav_go_s nav_go_left">zurück</span>
    </div>
  </div>
  <div v-if="edit" id="delete">
    <div class="item button" @click="deleteContent">
      <span class="more uppercase nav nav_go nav_go_s nav_go_right "
        >löschen</span
      >
    </div>
  </div>
  <div id="choose_content" class="overlay">
    <div class="item new_image">
      <!-- eslint-disable-next-line vue/no-mutating-props  -->
      <AdminLang v-model="en" :en="en" />
      <BaseInput
        v-if="en"
        v-model="content.title_en"
        label="Titel"
        type="text"
      />
      <BaseInput v-else v-model="content.title" label="Titel" type="text" />
      <select
        v-model="content.type"
        class="dropdown"
        name="amount"
        :disabled="edit"
      >
        <option value="0" default>Art auswählen</option>
        <option value="1">PDF</option>
        <option value="2">Video</option>
      </select>
      <template v-if="content.type == 1">
        <BaseTextarea v-if="en" v-model="content.text_en" label="Text" />
        <BaseTextarea v-else v-model="content.text" label="Text" />

        <input id="file_en" ref="file_en" type="file" @change="selectFileEn" />
        <label
          v-if="en"
          for="file_en"
          class="more uppercase nav nav_go nav_go_s nav_go_right"
          :class="{ white: !fileIsSelected && link_name == '' }"
          >{{
            edit
              ? link_name
                ? "PDF ersetzen"
                : "PDF auswählen"
              : "PDF auswählen"
          }}</label
        >
        <input id="file" ref="file" type="file" @change="selectFile" />
        <label
          v-if="!en"
          for="file"
          class="more uppercase nav nav_go nav_go_s nav_go_right"
          :class="{ white: !fileIsSelected && link_name == '' }"
          >{{
            edit
              ? link_name
                ? "PDF ersetzen"
                : "PDF auswählen"
              : "PDF auswählen"
          }}</label
        >
        <span
          class="more uppercase nav nav_go nav_go_s nav_go_right"
          :class="{ white: fileIsSelected }"
          @click="uploadFile"
          >{{ edit ? "Hochladen / Speichern" : "Hochladen" }}</span
        >
      </template>
      <template v-else-if="content.type == 2">
        <BaseInput
          v-model="content.link_name"
          label="YouTube Link"
          type="text"
        />
        <span
          class="more uppercase nav nav_go nav_go_s nav_go_right"
          @click="saveVideo"
          >Speichern</span
        >
      </template>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import BaseInput from "../components/BaseInput";
import BaseTextarea from "../components/BaseTextarea";
import AdminLang from "../components/AdminLang";

export default {
  components: { BaseInput, BaseTextarea, AdminLang },
  props: {
    c: {
      type: Object,
      default() {
        return {
          id: 0,
          type: 0,
          title: "",
          text: "",
          title_en: "",
          text_en: "",
          link_name: "",
          link_name_en: ""
        };
      }
    },
    edit: {
      type: Boolean,
      default: false
    },
    en: {
      type: Boolean,
      default: false
    }
  },
  emits: ["set-content", "close", "remove"],
  data() {
    return {
      content: {},
      isLoading: true,
      fileSelected: false,
      fileSelectedEn: false,
      file: null
    };
  },
  computed: {
    link_name() {
      return this.en ? this.content.link_name_en : this.content.link_name;
    },
    fileIsSelected() {
      return this.en ? this.fileSelectedEn : this.fileSelected;
    }
  },
  created() {
    this.content = this.c;
  },
  methods: {
    uploadFile() {
      if (this.edit) {
        this.replaceFile();
      } else {
        this.createFile();
      }
    },
    createFile() {
      let formData = new FormData();
      //  let file = e.target.files[0];
      if (this.fileSelected) {
        let file = this.$refs.file.files[0];
        formData.append("file", file);
      }
      if (this.fileSelectedEn) {
        let file = this.$refs.file_en.files[0];
        formData.append("file_en", file);
      }
      for (let k in this.content) {
        formData.append(k, this.content[k]);
      }
      //TODO: move to store
      axios
        .post(this.$store.state.connection + "/api/addContent", formData)
        .then(({ data }) => {
          //console.log(data.id + ' ' + data.link_name);
          this.setContent(data.id, data.link_name, data.link_name_en, true);
        });
    },
    replaceFile() {
      let formData = new FormData();
      //  let file = e.target.files[0];
      if (this.fileSelected) {
        let file = this.$refs.file.files[0];
        formData.append("file", file);
      }
      if (this.fileSelectedEn) {
        let file = this.$refs.file_en.files[0];
        formData.append("file_en", file);
      }
      for (let k in this.content) {
        formData.append(k, this.content[k]);
      }
      //TODO: move to store
      axios
        .post(this.$store.state.connection + "/api/replaceFile", formData)
        .then(({ data }) => {
          this.close();
          this.setContent(data.id, data.link_name, data.link_name_en, false);

          //console.log(data.id + ' ' + data.link_name);
        });
    },
    saveVideo() {
      if (this.edit) {
        this.editVideo();
      } else {
        this.addVideo();
      }
    },
    addVideo() {
      axios
        .post(this.$store.state.connection + "/api/addContent", this.content)
        .then(({ data }) => {
          //console.log(data.id + ' ' + data.link_name);
          this.setContent(data.id, data.link_name, data.link_name, true);
        });
    },
    editVideo() {
      axios
        .post(this.$store.state.connection + "/api/replaceFile", this.content)
        .then(({ data }) => {
          this.close();
          this.setContent(data.id, data.link_name, data.link_name, false);
          //console.log(data.id + ' ' + data.link_name);
        });
    },
    setContent(id, link, link_en, add) {
      this.content.id = id;
      this.content.link_name = link;
      this.content.link_name_en = link_en;
      if (add) {
        this.$emit("set-content", this.content);
      }
    },
    close() {
      this.content = {};
      this.$emit("close");
    },
    selectFile() {
      this.fileSelected = true;
    },
    selectFileEn() {
      this.fileSelectedEn = true;
    },
    deleteContent() {
      let data = { id: this.content.id };
      axios
        .delete(this.$store.state.connection + "/api/deleteContent", {
          data: data
        })
        .then(() => {
          this.$emit("remove", this.content.id);
          this.close();
        });
    }
  }
};
</script>

<style scoped lang="scss">
@import "../scss/_variables.scss";
@import "../scss/_admin.scss";
#choose_content {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  overflow: scroll;
  padding: 1rem;
}
.select {
  height: 30rem;
  width: auto;
  margin: 1rem;
  cursor: pointer;
}
.new_image {
  position: relative;
  height: 55rem;
  width: 40rem;
  margin: 1rem;
  margin-top: 10rem;
}
.submit {
  font-size: 2rem;
  cursor: pointer;
}
.new_image > .more,
.new_image > form > .more {
  margin: 1rem 0;
  display: block;
}
.new_image > .more.white,
.new_image > form > .more.white {
  color: white;
}
.new_image > .more {
  margin: 2rem 0;
}
.new_image > .more::before,
.new_image > form > .more::before {
  background: $mainBlue;
}
.new_image > .more.white::before,
.new_image > form > .more.white::before {
  background: white;
}
[type="file"] {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

[type="file"] + label {
  /* File upload button styles */
}

[type="file"]:focus + label,
[type="file"] + label:hover {
  /* File upload hover state button styles */
}

[type="file"]:focus + label {
  /* File upload focus state button styles */
}
#delete {
  position: fixed;
  top: 15rem;
  right: 20rem;
  z-index: 1001;
}
</style>
