<template>
  <AdminLang v-model="en" :en="en" />
  <div v-if="!isLoading" id="images">
    <div class="item new_image">
      <form>
        <BaseInput
          v-if="en"
          v-model="image.name_en"
          label="Titel/Bildlegende (en)"
          type="text"
        />
        <BaseInput
          v-else
          v-model="image.name"
          label="Titel/Bildlegende"
          type="text"
        />
        <BaseInput
          v-if="en"
          v-model="image.caption_en"
          label="Quelle (en)"
          type="text"
        />
        <BaseInput v-else v-model="image.caption" label="Quelle" type="text" />

        <input id="file" ref="file" type="file" @change="selectFile" />
        <label
          for="file"
          class="more uppercase nav nav_go nav_go_s nav_go_right"
          :class="{ white: !fileSelected }"
          >Bild auswählen</label
        >
      </form>
      <span
        class="more uppercase nav nav_go nav_go_s nav_go_right"
        :class="{ white: fileSelected }"
        @click="uploadImage"
        >Hochladen</span
      >
    </div>
    <div v-for="i in images" :key="i.id" class="select item">
      <img
        :src="
          $store.state.connection +
            '/storage/images/' +
            i.link_name +
            '_200.jpg'
        "
      />
      <form>
        <input v-if="en" v-model="i.name_en" class="caption" type="text" />
        <input v-else v-model="i.name" class="caption" type="text" />
        <input v-if="en" v-model="i.caption_en" class="caption" type="text" />
        <input v-else v-model="i.caption" class="caption" type="text" />
        <div class="options">
          <span
            class="more uppercase nav nav_go nav_go_s nav_go_right"
            @click="saveImage(i)"
            >Speichern</span
          >
          <span
            class="more uppercase nav nav_go nav_go_s nav_go_right"
            @click="deleteImage(i.id)"
            >Löschen</span
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import BaseInput from "../components/BaseInput";
import AdminLang from "../components/AdminLang";

export default {
  components: { BaseInput, AdminLang },
  props: {
    type: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      images: [],
      image: {
        name: "",
        caption: "",
        type: this.type
      },
      isLoading: true,
      fileSelected: false,
      file: null,
      en: false
    };
  },
  created() {
    this.getImages();
  },
  methods: {
    getImages() {
      axios
        .get(this.$store.state.connection + "/api/getImages/" + this.type)
        .then(({ data }) => {
          this.images = data;
          this.isLoading = false;
        });
    },
    uploadImage() {
      let formData = new FormData();
      //  let file = e.target.files[0];
      let file = this.$refs.file.files[0];
      formData.append("image", file);
      for (let k in this.image) {
        formData.append(k, this.image[k]);
      }
      //TODO: move to store
      axios
        .post(this.$store.state.connection + "/api/uploadImage", formData)
        .then(() => {
          //console.log(data.id + ' ' + data.link_name);
        });
    },
    selectFile() {
      this.fileSelected = true;
    },
    deleteImage(id) {
      let data = { id: id };
      axios
        .delete(this.$store.state.connection + "/api/deleteImage", {
          data: data
        })
        .then(() => {
          this.removeImage(id);
        });
    },
    saveImage(image) {
      axios
        .put(this.$store.state.connection + "/api/changeImage", image)
        .then(() => {});
    },
    removeImage(id) {
      let i = this.images.find(i => i.id === id);
      let index = this.images.indexOf(i);
      this.images.splice(index, 1);
    }
  }
};
</script>

<style scoped lang="scss">
@import "../scss/_variables.scss";
@import "../scss/_admin.scss";
#images {
  top: 15rem;
  height: 100%;
  width: 100%;
  background: $rainbow_0;
  display: flex;
  flex-direction: row;
  align-content: baseline;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow: scroll;
  padding: 1rem;
  padding-top: 10rem;
}

.new_image {
  max-height: 26rem;
  width: 40rem;
  margin: 1rem;
}
.submit {
  font-size: 2rem;
  cursor: pointer;
}
.more {
  margin: 1rem 0;
}
.more.white,
.more.white {
  color: white;
}
.more {
  margin: 2rem 0;
}
.more::before,
.more::before {
  background: $mainBlue;
}
.more.white::before,
.more.white::before {
  background: white;
}
[type="file"] {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

[type="file"] + label {
  /* File upload button styles */
}

[type="file"]:focus + label,
[type="file"] + label:hover {
  /* File upload hover state button styles */
}

[type="file"]:focus + label {
  /* File upload focus state button styles */
}
.caption {
  width: 100%;
  max-width: 100%;
}
form {
  width: 100%;
}
</style>
