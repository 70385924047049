<template>
  <div v-if="content.type != 1 || link_name != ''" :id="anchor" class="anchor">
    test
  </div>

  <div
    v-if="content.type != 1 || link_name != ''"
    class="reportage"
    :class="{
      mobile: mobile
    }"
  >
    <div>
      <span class="small">{{
        en ? "Reportage" : "Persönliche Geschichte"
      }}</span>
      <h1 class="title">{{ title }}</h1>
      <p v-if="content.type == 1" class="large" :class="{ mobile: mobile }">
        {{ text }}
      </p>
      <div v-else class="video">
        <iframe
          class="youtube"
          :src="getLink(content.link_name, false)"
          frameborder="0"
          allow="autoplay; encrypted-media"
          allowfullscreen
        ></iframe>
      </div>
      <a
        v-if="content.type == 1"
        :href="getLink(link_name, true)"
        target="_blank"
      >
        <span class="more mobile uppercase nav nav_go nav_go_s nav_go_right">{{
          en ? "Read more" : "Mehr erfahren"
        }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  computed: {
    mobile() {
      return this.$store.getters.mobile;
    },
    en() {
      return this.$store.getters.en;
    },
    title() {
      return this.en ? this.content.title_en : this.content.title;
    },
    text() {
      return this.en ? this.content.text_en : this.content.text;
    },
    link_name() {
      return this.en ? this.content.link_name_en : this.content.link_name;
    },
    anchor() {
      return this.content.link_name + "_reportage";
    }
  },
  methods: {
    getLink(name, pdf) {
      return pdf
        ? this.$store.state.connection + "/storage/files/" + name + ".pdf"
        : "https://www.youtube-nocookie.com/embed/" + name;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/_variables.scss";

.reportage {
  display: flex;
  align-content: center;
  justify-content: center;
  color: #fff;
  padding: 11rem 10rem 24rem 10rem;
}
.reportage.mobile {
  padding: 11rem 3.5rem 24rem 3.5rem;
}

a {
  color: inherit;
}
.more {
  margin-bottom: 0;
}
div {
  max-width: 160rem;
}
div > a > .nav_go::before {
  background: #fff;
}
p.large {
  margin: 0;
  font-size: 7rem;
  line-height: 7.5rem;
}
p.large.mobile {
  font-size: 3.5rem;
  line-height: 3.5rem;
}
.youtube {
  padding-top: 3.5rem;
  height: 66vh;
  width: 100vh;
  max-width: 100%;
}
</style>
