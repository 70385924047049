<template>
  <div id="delete">
    <div
      v-if="contributor.id > 0"
      class="item button"
      @click="deleteContributor"
    >
      <span class="more uppercase nav nav_go nav_go_s nav_go_right "
        >löschen</span
      >
    </div>
  </div>
  <div class="admin_center p">
    <div v-if="!isLoading" id="editContributor" class="edit">
      <div class="item">
        <form>
          <BaseInput v-model="contributor.title" label="Titel" type="text" />
          <BaseInput v-model="contributor.name" label="Vorname*" type="text" />
          <BaseInput
            v-model="contributor.surname"
            label="Nachname*"
            type="text"
          />
          <BaseInput
            v-model="contributor.function"
            label="Funktion"
            type="text"
          />
        </form>
      </div>
      <div
        class="item button"
        :class="{ mainb: !hasImage }"
        @click="openImgLayer"
      >
        <span class="more uppercase nav nav_go nav_go_s nav_go_right "
          >Bild {{ hasImage ? "ändern" : "hinzufügen" }}</span
        >
      </div>
      <div class="item button" :class="{ mainb: hasImage }" @click="checkForm">
        <span class="more uppercase nav nav_go nav_go_s nav_go_right "
          >Speichern</span
        >
      </div>
      <div v-if="success" class="success item">
        <span class="small">
          {{ notifications.success[0] }}
        </span>
      </div>
      <div v-else class="error item">
        <span v-for="(e, key) in notifications.errors" :key="key" class="small">
          {{ e }}
        </span>
      </div>
    </div>
    <div v-if="!isLoading" class="preview">
      <div class="flex item c">
        <Contributor :contributor="c" />
      </div>
    </div>
  </div>
  <div>
    <ChooseImage
      v-if="selectingImage"
      :type="imgType"
      @set-image="setImage($event)"
      @close="closeImgLayer"
    />
  </div>
</template>

<script>
import axios from "axios";
import BaseInput from "../components/BaseInput";
import Contributor from "../components/Contributor";
import ChooseImage from "../components/ChooseImage";

export default {
  components: {
    BaseInput,
    Contributor,
    ChooseImage
  },
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      contributor: {
        id: 0,
        name: "",
        surname: "",
        title: "",
        function: "",
        image: {},
        image_id: 0
      },
      isLoading: true,
      selectingImage: false,
      notifications: { errors: [], success: [] },
      imgType: 2
    };
  },
  computed: {
    c() {
      return this.contributor;
    },
    hasImage() {
      return this.contributor.image_id > 0;
    },
    error() {
      return this.notifications.errors.length > 0;
    },
    success() {
      return this.notifications.success.length > 0;
    }
  },
  created() {
    if (this.id === 0) {
      this.isLoading = false;
    } else {
      this.getContributor();
    }
  },
  methods: {
    getContributor() {
      if (this.id == 0) {
        this.isLoading = false;
      } else {
        axios
          .get(
            this.$store.state.connection + "/api/getContributor?id=" + this.id
          )
          .then(({ data }) => {
            this.contributor = data;
            this.isLoading = false;
          });
      }
    },
    checkForm(e) {
      e.preventDefault();
      //TODO: validate
      if (!this.contributor.name || !this.contributor.surname) {
        this.notifications.errors = [];
        this.notifications.errors.push("Bitte alle Felder ausfüllen");
      } else {
        this.notifications.errors = [];
        if (this.contributor.id > 0) {
          this.changeContributor();
        } else {
          this.addContributor();
        }
      }
      //TODO: check for errors
    },
    changeContributor() {
      this.$store
        .dispatch("changeContributor", this.contributor)
        .then(() => {
          this.notifications.success.push("Änderung gespeichert");

          //   this.$router.push({ name: "manageContributors" });
        })
        .catch(err => {
          if (err.response.status === 401) {
            this.notifications.errors = [];
            this.notifications.errors.push("keine Berechtigung");
          } else {
            this.notifications.errors.push(err.error);
          }
        });
    },
    addContributor() {
      const self = this;
      this.$store
        .dispatch("addContributor", this.contributor)
        .then(res => {
          self.contributor.id = res;
          this.notifications.success.push("Referent*in erstellt");

          //   this.$router.push({ name: "manageContributors" });
        })
        .catch(err => {
          if (err.response.status === 401) {
            this.notifications.errors = [];
            this.notifications.errors.push("keine Berechtigung");
          } else {
            this.notifications.errors.push(err.error);
          }
        });
    },
    deleteContributor(e) {
      e.preventDefault();
      this.$store
        .dispatch("deleteContributor", {
          id: this.contributor.id
        })
        .then(() => {
          this.$router.push({
            name: "manageContributors"
          });
        })
        .catch(err => {
          console.log(err);
          if (err.response.status && err.response.status === 401) {
            this.notifications.errors = [];
            this.notifications.errors.push("keine Berechtigung");
          } else {
            this.notifications.errors.push(err.error);
          }
        });
    },
    setImage(e) {
      this.contributor.image_id = e.id;
      this.contributor.image.link_name = e.link;
      this.selectingImage = false;
    },
    openImgLayer(e) {
      e.preventDefault();
      this.selectingImage = true;
    },
    closeImgLayer() {
      this.selectingImage = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/_variables.scss";
@import "../scss/_admin.scss";
textarea {
  width: 100%;
}
input {
}
.preview > .flex > .container {
}
.flex {
  display: flex;
  flex-wrap: wrap;
}
.delete {
  background: $lila;
}
</style>
