import { render } from "./Events.vue?vue&type=template&id=5901bf53&scoped=true"
import script from "./Events.vue?vue&type=script&lang=js"
export * from "./Events.vue?vue&type=script&lang=js"

import "./Events.vue?vue&type=style&index=0&id=5901bf53&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-5901bf53"
/* hot reload */
if (module.hot) {
  script.__hmrId = "5901bf53"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5901bf53', script)) {
    api.reload('5901bf53', script)
  }
  
  module.hot.accept("./Events.vue?vue&type=template&id=5901bf53&scoped=true", () => {
    api.rerender('5901bf53', render)
  })

}

script.__file = "src/views/Events.vue"

export default script