<template>
  <div
    v-if="ready && (title != '' || text != '')"
    :id="story.web_id"
    class="anchor"
  ></div>
  <div
    v-if="ready && (title != '' || text != '')"
    class="page"
    :class="{ reverse: odd, mobile: mobile }"
  >
    <teleport to="head">
      <meta property="og:title" :content="title" />
      <meta name="description" property="og:description" :content="text" />
      <meta property="og:image:url" :content="imageURL" />

      <meta property="og:image:url" content="../assets/default.jpg" />
    </teleport>
    <div
      class="story_preview"
      :class="{ reverse: odd, normal: !odd, mobile: mobile }"
    >
      <div
        class="story_bg"
        :style="{ 'background-image': 'url(' + image + ')' }"
        :class="{ reverse: odd, mobile: mobile }"
      ></div>
    </div>
    <div class="year" :class="{ mobile: mobile, reverse: odd }">
      <div class="" :class="{ rotate: !mobile }">
        <span class="month_title" :class="{ reverse: odd, mobile: mobile }">
          {{ year }}
        </span>
      </div>
    </div>
    <div class="content_container" :class="{ reverse: odd, mobile: mobile }">
      <div class="content" :class="{ mobile: mobile }">
        <span class="big">{{ title }}</span>
        <router-link
          :to="{
            name: 'jahr',
            params: { webid: story.web_id, id: story.id, odd: odd }
          }"
        >
          <span
            class="more uppercase nav nav_go nav_go_s nav_go_right"
            :class="{ mobile: mobile }"
            >{{ en ? "Read more" : "Mehr erfahren" }}</span
          >
        </router-link>
      </div>
      <div class="end" :class="{ reverse: odd, normal: !odd }">
        <svg class="end" viewBox="0 0 118.62 443.79">
          <polyline
            v-if="!odd"
            points="0 443.79 118.6 267.38 118.62 87.8 58.72 0 0 0 0 443.79"
            fill="#fff"
          />
          <polyline
            v-else
            class="cls-1"
            points="118.62 443.79 0.01 267.38 0 87.8 59.9 0 118.62 0 118.62 443.79"
            fill="#fff"
          />
        </svg>
      </div>
    </div>
  </div>
  <div v-if="ready && hasContent">
    <div
      v-for="(content, key) in story.content"
      :key="key"
      :class="{
        mobile: mobile,
        bg1: story.house == 1,
        bg2: story.house == 2,
        bg3: story.house == 3,
        reverse: isReversed(key)
      }"
    >
      <Reportage :content="content" />
    </div>
  </div>
</template>

<script>
//import axios from "axios";
import moment from "moment";
import Reportage from "./Reportage.vue";

export default {
  components: { Reportage },
  props: {
    story: {
      type: Object,
      default: () => {
        return {};
      }
    },
    oddk: {
      type: Number,
      default: 0
    },
    first: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      image: "",
      imageUrl: ""
    };
  },
  computed: {
    en() {
      return this.$store.getters.en;
    },
    title() {
      return this.en ? this.story.title_en : this.story.title;
    },
    text() {
      return this.en ? this.story.text_en : this.story.text;
    },
    odd() {
      return this.oddk % 2;
    },
    mobile() {
      return this.$store.getters.mobile;
    },
    small() {
      return this.$store.getters.break1;
    },
    loading() {
      return this.$store.getters.storyLoading;
    },
    ready() {
      return this.$store.getters.storyLoaded;
    },
    house() {
      return this.$store.getters.house[this.story.house];
    },
    year() {
      return moment(this.story.year, "YYYY-MM-DD").format("YYYY");
    },
    hasContent() {
      return this.story.content.length > 0;
    },
    imageURL() {
      if (this.story.image != null) {
        return (
          this.$store.state.connection +
          "/storage/images/" +
          this.story.image.link_name +
          "_800.jpg"
        );
      }
      return "";
    }
  },
  mounted: function() {
    this.setImage();
  },
  methods: {
    setImage() {
      if (this.story.image != null) {
        this.imageUrl =
          this.$store.state.connection +
          "/storage/images/" +
          this.story.image.link_name +
          "_800.jpg";
        this.image = "";
        const self = this;
        let image = new Image();
        image.onload = function() {
          self.image = self.imageUrl;
        };
        image.src = this.imageUrl;
      }
    },
    isReversed(x) {
      return this.odd ? !(x % 2) : x % 2;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/_variables.scss";

.house {
}
.page {
  position: relative;
  display: flex;
  align-items: flex-end;
  height: calc(100vh - 15rem);
}

.page.reverse {
  justify-content: flex-end;
}
.page.mobile {
  height: calc(100vh - 7.5rem);
}
.left {
  position: relative;
  display: flex;
}
.content_container {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-bottom: 10.5rem;
}
.content_container.reverse {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 10.5rem;
}
.content_container.mobile {
  margin-bottom: 3.5rem;
}
.content {
  background: #fff;
  width: 35rem;
  padding: 4rem 3rem 18rem 3rem;
}
.content.mobile {
  padding: 4rem 3rem 4rem 3rem;
  max-width: calc(100vw - 6rem);
}
svg.end {
  height: 100%;
}
div.end.normal > svg.end {
  transform: translate(-1px, 0);
}
div.end.reverse > svg.end {
  height: 100%;
  transform: translate(1px, 0);
}
div.end {
  position: absolute;
  height: 100%;
}
div.end.reverse {
  right: 100%;
}
div.end.reverse > svg {
  right: 0;
  position: absolute;
}
div.end.normal {
  left: 100%;
}
.story_preview {
  position: absolute;
  height: 100%;
  width: 122vh;
  transform: translate(0, -3.5rem);
}
.story_preview.mobile {
  transform: translate(0, 3.5em);
}

.story_preview.normal {
  right: 0;
}
.story_preview.reverse {
  left: 0;
  display: flex;
  flex-flow: column;
  place-items: flex-end;

  /*  ;*/
}
.story_preview.mobile {
  left: -5vh;
  width: auto;
}
.story_preview.mobile.reverse {
  right: -5vh;
}
.color1 {
  color: $green;
}
.color2 {
  color: $orange;
}
.color3 {
  color: $lila;
}
.story_bg {
  height: 90%;
  margin-top: 0;

  height: calc(100% - 3.5rem);
  width: calc(170vh - 3.5rem);
  background-repeat: no-repeat;
  background-size: cover;
  mask: url("../assets/Mask/maske_bild_jahr_zugeschnitten.svg");
  mask-repeat: no-repeat;
}

.story_bg.reverse {
  mask: url("../assets/Mask/maske_bild_jahr_zugeschnitten_L.svg");
  mask-repeat: no-repeat;
}
.story_bg.mobile {
  height: calc(100vw - 3.5rem);
  width: calc(200vw - 3.5rem);
}
span.month_title.reverse {
  line-height: 6rem;
}
.year {
  position: absolute;
  width: 10.5rem;
  top: 3.5rem;
}
.year.mobile {
  // top: -3.5rem;
  top: -3.5em;
}
.year.mobile.reverse {
  // top: -3.5rem;
  top: 0em;
}
.month_title.mobile {
  font-size: 3.5rem;
  margin-left: 3rem;
}
.year.mobile.reverse > div > .month_title.mobile {
  margin-left: 0rem;
}
.nav_go::before {
  background: $mainBlue;
}

a {
  color: inherit;
}
.more {
  margin-bottom: 0;
}
</style>
