<template>
  <div class="contributors">
    <Contributor
      v-for="(contributor, key) in contributors"
      :key="key"
      :contributor="contributor"
    />
  </div>
</template>

<script>
import Contributor from "../components/Contributor";

export default {
  name: "Contributors",
  components: { Contributor },

  props: {
    contributors: {
      type: Array,
      default: () => {
        return [];
      }
    }
  }
};
</script>

<style scoped>
.contributors {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4rem;
}
</style>
