<template>
  <div :id="event.web_id" class="anchor"></div>

  <div
    class="event"
    :class="{
      special: event.type > 2,
      theme: event.type == 2,
      mobile: mobile
    }"
  >
    <div v-if="!mobile" class="start" :class="{ desktop: !mobile }">
      <svg class="start" viewBox="0 0 108 240" :class="{ mobile: mobile }">
        <polygon points="55 240 0 160 108 0 108 240 55 240" :fill="fill" />
      </svg>
      <div v-if="!mobile" class="date">
        <span class="small top">{{ event.place }}</span>
        <span v-if="date" class="big uppercase">{{ date }}</span>
        <span v-if="time" class="big uppercase">{{ time }} Uhr</span>
      </div>
    </div>
    <img v-if="hasImage && !small" class="image" :src="imageUrl" />
    <div v-if="mobile" class="mobile_bg_container">
      <div class="start mobile">
        <svg class="start mobile" viewBox="0 0 108 240">
          <polygon points="55 240 0 160 108 0 108 240 55 240" :fill="fill" />
        </svg>
      </div>
      <div class="mobile_bg"></div>
      <div class="end mobile">
        <svg class="end" viewBox="0 0 108 240" :class="{ mobile: mobile }">
          <polygon points="54 0 108 80 0 240 0 0 54 0" :fill="fill" />
        </svg>
      </div>
    </div>
    <div
      class="content"
      :class="{
        padding_left_3: hasImage && !mobile,
        mobile: mobile,
        desktop: !mobile,
        shrink: !mobile && shrink
      }"
    >
      <div v-if="mobile" class="date mobile">
        <span class="small top mobile">{{ event.place }}</span>
        <span v-if="date" class="big uppercase mobile">{{ date }}</span>
        <span v-if="time" class="big uppercase mobile">{{ time }} Uhr</span>
      </div>
      <span class="small top" :class="{ mobile: mobile }">
        {{ types[event.type] }}
      </span>
      <router-link
        :to="{ name: 'event', params: { webid: event.web_id, id: event.id } }"
      >
        <h1 class="title">{{ title }}</h1>
      </router-link>
      <h2 v-if="!mobile && subtitle" class="subtitle">{{ subtitle }}</h2>
      <a v-if="event.online" :href="youtube" target="_blank">
        <span
          class="more uppercase nav nav_go nav_go_s nav_go_right out"
          :class="{ mobile: mobile }"
        >
          Online
        </span>
      </a>
      <router-link
        v-else-if="event.canceled"
        :to="{ name: 'event', params: { webid: event.web_id, id: event.id } }"
      >
        <span
          class="more uppercase nav nav_go nav_go_s nav_go_right out"
          :class="{ mobile: mobile }"
        >
          Abgesagt
        </span>
      </router-link>
      <router-link
        :to="{ name: 'event', params: { webid: event.web_id, id: event.id } }"
      >
        <span
          v-if="!event.registration"
          class="more uppercase nav nav_go nav_go_s nav_go_right"
          :class="{ mobile: mobile }"
          >Mehr erfahren</span
        >
      </router-link>
      <router-link
        :to="{ name: 'event', params: { webid: event.web_id, id: event.id } }"
      >
        <span
          v-if="event.registration"
          class="more uppercase nav nav_go nav_go_s nav_go_right"
          :class="{ mobile: mobile, out: event.max_visitors < 1 }"
        >
          {{ anmelden }}</span
        >
      </router-link>
      <!--  <Contributors v-if="hasContributors" :contributors="event.contributors" />-->
    </div>
    <svg v-if="!mobile" class="end" viewBox="0 0 108 240">
      <polygon points="54 0 108 80 0 240 0 0 54 0" :fill="fill" />
    </svg>
  </div>
</template>

<script>
//import Contributors from "../components/Contributors";
import moment from "moment";

export default {
  name: "Event",
  components: {},
  props: {
    event: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      types: {
        1: "Gesundheitsforum",
        2: "Thementag",
        3: "Spezial",
        4: "Konzert"
      }
    };
  },
  computed: {
    title() {
      let line = this.shrink ? 47 : 48;
      let lines = 3;
      let maxLength = lines * line;

      return this.trim(this.event.title, maxLength);
    },
    titleLength() {
      let line = this.shrink ? 47 : 48;
      return Math.ceil(this.event.title.length / line);
    },
    subtitleLength() {
      let line = this.shrink ? 47 : 48;
      if (this.event.subtitle.length != 0) {
        return Math.ceil(this.event.subtitle.length / line);
      } else if (this.event.text.length != 0) {
        return Math.ceil(this.event.text.length / line);
      }
      return 0;
    },
    subtitle() {
      let line = this.shrink ? 47 : 48;
      if (this.titleLength > 2) {
        return false;
      }
      let lines = 3 - this.titleLength;
      let maxLength = lines * line;
      if (this.event.subtitle != "") {
        return this.trim(this.event.subtitle, maxLength);
        //  return this.event.subtitle.substring(0, length);
      } else {
        return this.trim(this.event.text, maxLength);
      }
    },
    mobile() {
      return this.$store.getters.mobile;
    },
    small() {
      return this.$store.getters.break2;
    },
    shrink() {
      return this.$store.getters.break3;
    },
    hasContributors() {
      return this.event.contributors.length;
    },
    hasImage() {
      return this.event.image == 1;
    },
    imageUrl() {
      if ("images" in this.event && this.event.image == 1) {
        if (this.event.images.length > 0) {
          return (
            this.$store.state.connection +
            "/storage/images/" +
            this.event.images[0].link_name +
            "_300.jpg"
          );
        } else {
          return "";
        }
      }
      return "";
    },
    fill() {
      if (this.event.type == 2) {
        return "#eb7a14";
      } else if (this.event.type > 2) {
        return "#bd388d";
      }
      return "#b9b955";
    },
    date() {
      return this.event.date
        ? moment(this.event.date, "YYYY-MM-DD").format("dd D.M.")
        : false;
    },
    time() {
      if (this.event.time == "00:00:00") {
        return "";
      }
      if (this.event.time) {
        if (moment(this.event.time, "HH-mm-ss").format("m") != 0) {
          return moment(this.event.time, "HH-mm-ss").format("H:mm");
        }
        return moment(this.event.time, "HH-mm-ss").format("H");
      }
      return false;
    },
    anmelden() {
      return this.event.max_visitors < 1 ? "ausgebucht" : "anmelden";
    },
    youtube() {
      if (this.event.link != "") {
        return this.event.link;
      }
      return "https://www.youtube.com/channel/UC5GRB0GWq9MD-yua_mQAwgQ";
    }
  },
  created() {},
  methods: {
    trim(str, maxLen) {
      const separator = " ";
      if (str.length <= maxLen) {
        if (this.titleLength + this.subtitleLength < 3) {
          if (this.titleLength + this.subtitleLength < 2) {
            return str + "\n \n \n";
          }
          if (this.subtitleLength == 0) {
            return str + "\n";
          }
          return str + "\n \n";
        }
        return str;
      }
      return str.substr(0, str.lastIndexOf(separator, maxLen)) + "…";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/_variables.scss";
.mobile_container {
  position: relative;
}
.mobile_bg {
  position: relative;
  height: 100%;
  min-width: 55%;
  top: 0;
  bottom: 0;
}
.mobile_bg_container {
  display: flex;
  justify-content: center;

  position: absolute;
  top: 0;
  bottom: 0;
  min-width: 120%;
  left: -10%;
}
/*



*/
.router-link-active {
  text-decoration: none;
  color: inherit;
}
a {
  color: inherit;
}

.event {
  display: flex;
}
.event.mobile {
  flex-direction: row;
  position: relative;
  margin-bottom: 6rem;
}
div.start {
}
div.start.desktop {
  width: 21rem;
  display: flex;
  justify-content: flex-end;
}
div.start.mobile {
  height: 100%;
  transform: scaleY(-1);
}
div.end.mobile {
  height: 100%;
  transform: scaleY(-1);
}
.date {
  position: absolute;
  z-index: 0;
}
.date,
.content {
  padding: 3rem 0 3rem 0;
  color: #0a268a;
}

svg.start {
  height: 24rem;
}
svg.end {
  height: 24rem;
}

svg.end.mobile,
svg.start.mobile {
  height: 100%;
  width: auto;
  position: relative;
}
svg.start {
  transform: translate(1px, 0);
}
svg.end {
  transform: translate(-1px, 0);
}
/*
.content::before {
  margin-top: -3rem;
}*/
.date {
  width: 21rem;
  color: #fff;
}

.event > .content,
.event > .mobile_bg_container > .mobile_bg {
  background: #b9b955;
}
.event.special > .content,
.event.special > .mobile_bg_container > .mobile_bg {
  background: #bd388d;
}
.event.theme > .content,
.event.theme > .mobile_bg_container > .mobile_bg {
  background: #eb7a14;
}
.event.mobile > .content {
  background: none;
}
.content {
  width: 73.5rem;
}

.image {
  border: none;
  width: 21rem;
  height: 25.5rem;
  background: #0a268a;
  object-fit: cover;
  z-index: 0;
}
/*
span.small.mobile {
  font-size: 2.5rem;
  line-height: 2.75rem;
}*/
span.top {
  margin-bottom: 0.2rem;
  line-height: 2rem;
}

span.big.mobile {
  display: block;
}
.nav_go:before {
  background-color: #0a268a;
}
span.more {
  margin-top: 1.1rem;
}

.padding_left_3 {
  padding-left: 3rem;
}

.content.desktop {
  height: 18rem;
  margin-bottom: 1.5rem;
  z-index: 0;
}
.content.shrink {
  width: 66%;
}
.content.mobile {
  padding: 5.3rem 0 3.3rem 0;
  color: #fff;
  width: 100%;
  position: relative;
  margin-left: 2.25rem;
  margin-right: 2.25rem;
}
.content.mobile > .nav_go::before {
  background: #fff;
}
.nav_go.mobile::before {
  background: #fff;
}
.date.mobile {
  width: 100%;
  position: relative;
  padding: 0 0 1.5rem 0;
}
span.more.mobile {
  font-size: 2.5rem;
  margin-bottom: 0;
  margin-top: 2rem;
}
</style>
