import { render } from "./ManageEvents.vue?vue&type=template&id=517745d0&scoped=true"
import script from "./ManageEvents.vue?vue&type=script&lang=js"
export * from "./ManageEvents.vue?vue&type=script&lang=js"

import "./ManageEvents.vue?vue&type=style&index=0&id=517745d0&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-517745d0"
/* hot reload */
if (module.hot) {
  script.__hmrId = "517745d0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('517745d0', script)) {
    api.reload('517745d0', script)
  }
  
  module.hot.accept("./ManageEvents.vue?vue&type=template&id=517745d0&scoped=true", () => {
    api.rerender('517745d0', render)
  })

}

script.__file = "src/views/ManageEvents.vue"

export default script