<template>
  <div v-if="!mobile || (mobile && !hasEvents)" id="home" class="page">
    <!-- v-if="(!mobile && secondColumn) || !hasEvents" -->
    <div
      v-if="
        (secondColumn && storyReady && story) ||
          (!hasEvents && storyReady && story)
      "
      class="story_preview"
    >
      <div
        class="story_bg"
        :style="{ 'background-image': 'url(' + image + ')' }"
      ></div>
      <div class="lead click">
        <router-link
          :to="{
            name: 'jahr',
            params: { webid: story.web_id, id: story.id }
          }"
        >
          <div class="lead_content">
            <span class="more nav nav_go nav_go_s nav_go_right">{{
              year
            }}</span>
            <span class="big">{{ story.title }} </span>
          </div>
        </router-link>
      </div>
    </div>
    <div class="months">
      <div class="month"></div>

      <div class="events">
        <template v-if="eventReady && hasEvents">
          <Event
            v-for="event in currentEvents"
            :key="event.id"
            :event="event"
          />
        </template>
        <p v-else>
          Loading events
        </p>
      </div>
    </div>
  </div>
  <div v-else class="space"></div>
  <Events v-if="!showStory && hasEvents" :hidefirst="!mobile" />

  <Story v-else />
</template>

<script>
import Events from "../views/Events";
import Story from "../views/Story";
import moment from "moment";
import Event from "../components/EventPreview";
import { authComputed } from "../vuex/helpers.js";
export default {
  components: { Event, Events, Story },
  data() {
    return {
      isLoading: true,
      events: [],
      showStory: false
    };
  },
  computed: {
    ...authComputed,
    mobile() {
      return this.$store.getters.mobile;
    },
    currentEvents: function() {
      //  return this.$store.getters["upcomingEvents"];
      return this.$store.getters["currentEvents"](2);
      //  return this.$store.getters["passedEvents"];
    },
    secondColumn() {
      return this.$store.getters.secondColumn;
    },
    storyReady: function() {
      return (
        this.$store.getters.storyLoaded &&
        this.$store.getters.storyHome.length > 0
      );
    },
    storyLoading() {
      return this.$store.getters.storyLoading;
    },
    eventReady: function() {
      return this.$store.getters.eventsLoaded;
    },
    eventLoading() {
      return this.$store.getters.eventsLoading;
    },
    hasEvents() {
      return this.$store.getters.upcomingEvents.length > 0;
    },
    year() {
      return moment(this.story.year, "YYYY-MM-DD").format("YYYY");
    },
    story() {
      return this.$store.getters.storyHome[
        Math.floor(Math.random() * this.$store.getters.storyHome.length)
      ];
    },
    image() {
      if (this.story.image != null) {
        return (
          this.$store.state.connection +
          "/storage/images/" +
          this.story.image.link_name +
          "_800.jpg"
        );
      }
      return "";
    }
  },
  created() {
    if (!this.eventReady && !this.eventLoading) {
      this.$store.dispatch("loadEvents");
    }
    if (!this.storyReady && !this.storyLoading) {
      this.$store.dispatch("loadStory");
    }
  },
  methods: {
    goToStory(bool) {
      this.showStory = bool;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../scss/_variables.scss";

#home {
  position: relative;
  display: flex;
  align-items: center;
}
.space {
  min-height: calc(90vw - 15rem);
}
.story_preview {
  position: absolute;
  right: -20vh;
  top: -15rem;
  height: 100%;
  height: calc(100% + 15rem);
  width: 122vh;
}
.story_bg {
  height: 90%;
  height: calc(100% - 3rem);
  width: 186%;
  background-size: contain;
  mask: url("../assets/Mask/maske_bild_jahr_zugeschnitten.svg");
}
.lead {
  mask: url("../assets/Mask/bg_lead_geschichte_landingpage.svg");
  mask-repeat: no-repeat;
  mask-size: cover;
  background: white;
  min-height: 16rem;
  position: absolute;
  bottom: 0;
  right: 20vh;
  width: 73%;
}
.nav_go::before {
  background: $mainBlue;
}
.more {
  margin: 0;
}
.lead_content {
  padding: 3rem;
  padding-left: 6rem;
}
.page {
  overflow-x: hidden;
}
</style>
