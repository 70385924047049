<template>
  <div v-if="story" class="story_single">
    <teleport to="head">
      <!--  <title>{{ event.title }}</title>-->
      <meta property="og:title" :content="title" />
      <meta name="description" property="og:description" :content="text" />
      <meta property="og:image:url" :content="image" />
    </teleport>
    <div
      v-if="ready"
      class="page"
      :class="{
        bg1: story.house == 1,
        bg2: story.house == 2,
        bg3: story.house == 3,
        reverse: odd
      }"
    >
      <teleport to="head">
        <title>{{ title }}</title>
        <meta name="description" property="og:description" :content="text" />
        <meta property="og:image:url" :content="image" />
        <meta property="og:image:url" content="../assets/default.jpg" />
      </teleport>
      <div
        v-if="!mobile"
        class="story_preview"
        :class="{ reverse: odd, normal: !odd }"
      >
        <div
          class="story_bg"
          :style="{ 'background-image': 'url(' + image + ')' }"
          :class="{ reverse: odd }"
        ></div>
      </div>

      <div class="content_container" :class="{ reverse: odd, mobile: mobile }">
        <div
          class="content_flex"
          :class="{ reverse: odd, c: !secondColumn, mobile: mobile }"
        >
          <div
            class="content left "
            :class="{
              padding_bottom: secondColumn || (!secondColumn && !hasImages)
            }"
          >
            <div class="column">
              <!--
              <span
                class="small house"
                :class="{
                  color1: story.house == 1,
                  color2: story.house == 2,
                  color3: story.house == 3
                }"
                >{{ house }}</span
              > -->
              <span class="big">{{ title }}</span>
              <p>{{ text }}</p>
            </div>
          </div>
          <div
            v-if="hasImages"
            class="content"
            :class="{
              right: secondColumn,
              left: !secondColumn,
              padding_bottom: !secondColumn
            }"
          >
            <div v-for="(i, key) in story.images" :key="key" class="image">
              <img
                class="image"
                :src="getImgLink(i.link_name, 300)"
                :alt="i.name"
                @click="openImage(key)"
              />
            </div>
            <div></div>
          </div>
        </div>
        <div class="end" :class="{ reverse: odd, normal: !odd }">
          <svg class="end" viewBox="0 0 118.62 443.79">
            <polyline
              v-if="!odd"
              points="0 443.79 118.6 267.38 118.62 87.8 58.72 0 0 0 0 443.79"
              fill="#fff"
            />
            <polyline
              v-else
              class="cls-1"
              points="118.62 443.79 0.01 267.38 0 87.8 59.9 0 118.62 0 118.62 443.79"
              fill="#fff"
            />
          </svg>
        </div>
      </div>
      <div class="year" :class="{ mobile: mobile }">
        <div class="" :class="{ rotate: !mobile }">
          <span class="month_title" :class="{ reverse: odd, mobile: mobile }">
            {{ year }}
          </span>
        </div>
      </div>

      <div v-if="showImage" class="overlay series" :class="{ mobile: mobile }">
        <div class="slider">
          <img
            class="previous"
            src="../assets/Nav/nav_pfeil.svg"
            @click="previousImage"
          />
          <div>
            <img
              class="image big"
              :src="getImg('link')"
              :alt="getImg('name')"
              @click="nextImage"
            />
            <p class="caption">
              {{ getImg("name") }} <br />
              {{ getImg("caption") }}
            </p>
          </div>
          <img
            class="next"
            src="../assets/Nav/nav_pfeil.svg"
            @click="nextImage"
          />
        </div>
      </div>
      <div v-if="showImage" class="closeImage" :class="{ mobile: mobile }">
        <svg class="closeImage" viewBox="0 0 110 80.67" @click="closeImage">
          <g>
            <line
              class="cls-3"
              x1="2.16"
              y1="3.06"
              x2="107.84"
              y2="77.61"
              style="stroke:#fff;stroke-width:7.5px;"
            />
            <line
              class="cls-3"
              x1="107.84"
              y1="3.06"
              x2="2.16"
              y2="77.61"
              style="stroke:#fff;stroke-width:7.5px;"
            />
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
//import axios from "axios";
import moment from "moment";

export default {
  components: {},
  props: {},
  data() {
    return {
      odd: false,
      showImage: false,
      imageShowing: 0
    };
  },
  computed: {
    en() {
      return this.$store.getters.en;
    },
    title() {
      return this.en ? this.story.text_en : this.story.text;
    },
    text() {
      return this.en ? this.story.title_en : this.story.title;
    },
    secondColumn() {
      return this.$store.getters.secondColumn;
    },
    story() {
      if (this.$route.params.id) {
        return this.$store.getters.storyById(this.$route.params.id);
      } else {
        return this.$store.getters.storyByWebId(this.$route.params.webid);
      }
      //  console.log(this.$route.params.data);
      //  return this.$route.params.data;
    },
    mobile() {
      return this.$store.getters.mobile;
    },
    small() {
      return this.$store.getters.break1;
    },
    loading() {
      return this.$store.getters.storyLoading;
    },
    ready() {
      return this.$store.getters.storyLoaded;
    },
    house() {
      return this.$store.getters.house[this.story.house];
    },
    year() {
      return moment(this.story.year, "YYYY-MM-DD").format("YYYY");
    },
    image() {
      if (this.story.image != null) {
        return (
          this.$store.state.connection +
          "/storage/images/" +
          this.story.image.link_name +
          "_800.jpg"
        );
      }
      return "";
    },
    hasImages() {
      return this.story.images.length > 0;
    }
  },
  mounted: function() {
    if (this.$route.params.odd) {
      this.odd = this.$route.params.odd == 1;
    }
  },
  created() {
    if (!this.ready && !this.loading) {
      this.$store.dispatch("loadStory");
    }
  },
  methods: {
    openImage(id) {
      this.imageShowing = id;
      this.showImage = true;
    },
    closeImage() {
      this.showImage = false;
    },
    getImg(action) {
      let img = this.story.images[this.imageShowing];
      if (action == "link") {
        return this.getImgLink(img.link_name, 800);
      } else if (action == "name") {
        return this.en ? img.name_en : img.name;
      } else if (action == "caption") {
        return this.en ? img.caption_en : img.caption;
      }
    },
    nextImage() {
      if (this.story.images.length - 1 > this.imageShowing) {
        this.imageShowing++;
      } else {
        this.imageShowing = 0;
      }
    },
    previousImage() {
      if (this.imageShowing > 0) {
        this.imageShowing--;
      } else {
        this.imageShowing = this.story.images.length - 1;
      }
    },
    getLink(name, pdf) {
      return pdf
        ? this.$store.state.connection + "/storage/files/" + name + ".pdf"
        : "https://www.youtube-nocookie.com/embed/" + name;
    },
    getImgLink(name, size) {
      return (
        this.$store.state.connection +
        "/storage/images/" +
        name +
        "_" +
        size +
        ".jpg"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/_variables.scss";
.story_single {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100vh; //allowing Safari to scroll
}
.page {
  position: relative;
  display: flex;
  align-items: flex-start;
  min-height: calc(100vh - 15rem);
}
.page.reverse {
  justify-content: flex-end;
}

.content_container {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 10.5rem;
}
.content_container.reverse {
  flex-direction: row-reverse;
}
.content_container.small {
  flex-direction: column;
}
.content_container.mobile {
  margin-top: 0;
}
.column {
  max-width: 73.5rem;
}
.content {
  background: #fff;
  padding: 4rem 0rem 3rem 0rem;
}

.content.left {
  width: 70rem;
  min-width: 70rem;
  width: 100%;

  max-width: 102.4rem;
}
.content.right {
  padding: 4.5rem 9rem 18rem 12rem;
}
.content_container.reverse > .content.left {
  background: #fff;
  padding: 4rem 0rem 18rem 3rem;
}
.padding_bottom {
  padding-bottom: 18rem;
}
.content_flex {
  background: white;
  display: flex;
  padding-left: 31.5rem;
  padding-right: 3rem;
  flex-direction: row;
}
.content_flex.reverse {
  padding-right: 31.5rem;
  padding-left: 3rem;
}
.content_flex.c {
  flex-direction: column;
}
.content_flex.mobile {
  padding-top: 3.5rem;

  padding-left: 3.5rem;
  padding-right: 3.5rem;
}
.content_flex.mobile > .content {
  min-width: 0;
  width: 100%;
}
svg.end {
  height: 100%;
  transform: translate(0, 0);
}
div.end {
  position: absolute;
  height: 100%;
}
div.end.reverse {
  right: 100%;
}
div.end.reverse > svg.end {
  right: 100%;
  position: absolute;
}
div.end.normal {
  left: 100%;
}
.story_preview {
  position: fixed;
  top: 18.5rem;
  height: calc(100% - 25.5rem);
  width: 122vh;
  /*  ;*/
}
.story_preview.normal {
  right: 0;
}
.story_preview.reverse {
  left: 0;
  display: flex;
  flex-flow: column;
  place-items: flex-end;

  /*  ;*/
}
.color1 {
  color: $green;
}
.color2 {
  color: $orange;
}
.color3 {
  color: $lila;
}
.story_bg {
  height: 90%;
  margin-top: 0;
  height: calc(100% - 3.5rem);
  width: calc(170vh - 3.5rem);
  background-repeat: no-repeat;
  background-size: cover;
  mask: url("../assets/Mask/maske_bild_jahr_zugeschnitten.svg");
  mask-repeat: no-repeat;
}
.story_bg.reverse {
  mask: url("../assets/Mask/maske_bild_jahr_zugeschnitten_L.svg");
  mask-repeat: no-repeat;
}
span.month_title {
  color: $mainBlue;
}
span.month_title.reverse {
  line-height: 6rem;
}
.year {
  position: absolute;
  width: 10.5rem;
  top: 10.5rem;
}

.nav_go::before {
  background: $mainBlue;
}

a {
  color: inherit;
}
.more {
  margin-bottom: 0;
}

.bg1 {
  background: $bgGreen_reverse;
  position: relative;
}
.bg2 {
  background: $bgOrange_reverse;
  position: relative;
}
.bg3 {
  background: $bgLila_reverse;
  position: relative;
}
.bg1.reverse {
  background: $bgGreen;
  position: relative;
}
.bg2.reverse {
  background: $bgOrange;
  position: relative;
}
.bg3.reverse {
  background: $bgLila;
  position: relative;
}
div.image {
  padding-bottom: 3.5rem;
  max-height: 21rem;
  cursor: pointer;
}
img.image {
  max-height: 21rem;
  width: auto;
}
img.image.big {
  max-height: 75vh;
  max-width: 75vw;
  width: auto;
  height: auto;
}
.series {
  display: flex;
  justify-content: center;
  align-items: center;
}
.year.mobile {
  top: -3.5rem;
}
.month_title.mobile {
  font-size: 3.5rem;
  margin-left: 3rem;
}
div.closeImage {
  position: fixed;
  top: 18rem;
  right: 4rem;
  width: 5.5rem;
  cursor: pointer;

  z-index: 1001;
}
div.closeImage.mobile {
  top: 10rem;
  right: 2.25rem;
}
svg.closeImage {
  width: 5.5rem;
}
.previous {
  height: 6rem;
  transform: rotate(180deg);
  margin-right: 4rem;
  cursor: pointer;
  margin-bottom: 3rem;
}
.next {
  height: 6rem;
  margin-left: 4rem;
  cursor: pointer;
  margin-bottom: 3rem;
}
.slider {
  display: flex;
  align-items: center;
}
p.caption {
  color: #fff;
}
</style>
