<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <label> {{ label }} </label>
  <input
    v-bind="$attrs"
    type="checkbox"
    :checked="modelValue"
    :placeholder="label"
    class="field"
    @change="$emit('update:modelValue', $event.target.checked)"
  />
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    modelValue: {
      type: [String, Number, Date, Boolean],
      default: ""
    }
  },
  emits: ["update:modelValue"]
};
</script>
