import { render } from "./EditEvent.vue?vue&type=template&id=b8398c34&scoped=true"
import script from "./EditEvent.vue?vue&type=script&lang=js"
export * from "./EditEvent.vue?vue&type=script&lang=js"

import "./EditEvent.vue?vue&type=style&index=0&id=b8398c34&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-b8398c34"
/* hot reload */
if (module.hot) {
  script.__hmrId = "b8398c34"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('b8398c34', script)) {
    api.reload('b8398c34', script)
  }
  
  module.hot.accept("./EditEvent.vue?vue&type=template&id=b8398c34&scoped=true", () => {
    api.rerender('b8398c34', render)
  })

}

script.__file = "src/views/EditEvent.vue"

export default script