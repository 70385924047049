import { render } from "./ManageContributors.vue?vue&type=template&id=4d003392&scoped=true"
import script from "./ManageContributors.vue?vue&type=script&lang=js"
export * from "./ManageContributors.vue?vue&type=script&lang=js"

import "./ManageContributors.vue?vue&type=style&index=0&id=4d003392&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-4d003392"
/* hot reload */
if (module.hot) {
  script.__hmrId = "4d003392"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4d003392', script)) {
    api.reload('4d003392', script)
  }
  
  module.hot.accept("./ManageContributors.vue?vue&type=template&id=4d003392&scoped=true", () => {
    api.rerender('4d003392', render)
  })

}

script.__file = "src/views/ManageContributors.vue"

export default script