import { render } from "./ChooseContent.vue?vue&type=template&id=5696bd3c&scoped=true"
import script from "./ChooseContent.vue?vue&type=script&lang=js"
export * from "./ChooseContent.vue?vue&type=script&lang=js"

import "./ChooseContent.vue?vue&type=style&index=0&id=5696bd3c&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-5696bd3c"
/* hot reload */
if (module.hot) {
  script.__hmrId = "5696bd3c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5696bd3c', script)) {
    api.reload('5696bd3c', script)
  }
  
  module.hot.accept("./ChooseContent.vue?vue&type=template&id=5696bd3c&scoped=true", () => {
    api.rerender('5696bd3c', render)
  })

}

script.__file = "src/components/ChooseContent.vue"

export default script