<template>
  <div
    v-if="ready"
    class="bg"
    :class="{
      special: event.type > 2,
      theme: event.type == 2,
      mobile: mobile
    }"
  >
    <teleport to="head">
      <!--  <title>{{ event.title }}</title>-->
      <meta property="og:title" :content="event.title" />
      <meta
        name="description"
        property="og:description"
        :content="event.text"
      />
      <meta v-if="hasImage" property="og:image:url" :content="imageUrlOg" />
      <meta v-else property="og:image:url" content="../assets/default.jpg" />
    </teleport>
  </div>
  <div v-else class="bg blue"></div>
  <div
    v-if="ready"
    class="event"
    :class="{
      special: event.type > 2,
      theme: event.type == 2,
      mobile: mobile
    }"
  >
    <div v-if="!mobile" class="month">
      <div class="month_title">
        <div class="rotate">
          <span class="uppercase month_title">{{ month }}</span>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="content_container">
        <div class="left">
          <div class="start">
            <svg class="start" viewBox="0 0 213.71 311.91">
              <polygon
                points="0.05 311.91 0 311.86 213.72 0 213.72 311.91 0.05 311.91"
                :fill="fill"
              />
            </svg>
            <div v-if="!mobile" class="date">
              <span class="small top">{{ event.place }}</span>
              <span class="big uppercase">{{ date }}</span>
              <span class="big uppercase">{{ time }}</span>
            </div>
          </div>
          <div class="middle"></div>
        </div>

        <!--    <div v-if="hasImage && !small" class="image"></div>-->

        <div class="content desktop flex" :class="{ column: !secondColumn }">
          <div
            class="content left"
            :class="{ mobile: small, translate_down: !hasImage }"
          >
            <div class="column" :class="{ mobile: small }">
              <span v-if="mobile" class="small top">{{ event.place }}</span>
              <span v-if="mobile" class="big uppercase margin_4_b"
                >{{ date }}, {{ time }}</span
              >
              <span class="small top">
                {{ types[event.type] }}
              </span>
              <h1 class="title">{{ event.title }}</h1>
              <h2 class="subtitle">{{ event.subtitle }}</h2>
              <p>
                {{ event.text }}
              </p>
              <div
                v-if="
                  (event.video_id != null && event.link != '') || isLivestream
                "
                class="video"
              >
                <iframe
                  v-if="!isLivestream"
                  class="youtube"
                  :src="
                    'https://www.youtube-nocookie.com/embed/' + event.video_id
                  "
                  frameborder="0"
                  allow="autoplay; encrypted-media"
                  allowfullscreen
                ></iframe>
                <iframe
                  v-else
                  class="youtube"
                  :src="event.link"
                  frameborder="0"
                  allow="autoplay; encrypted-media"
                  allowfullscreen
                ></iframe>
              </div>
              <p class="info">
                {{ event.info }}
              </p>
              <p>
                <a
                  v-if="event.link != '' && event.video_id == null"
                  :href="event.link"
                  target="_blank"
                  class="link"
                  >{{ event.link_name }}</a
                >
              </p>
              <a v-if="event.online" :href="youtube" target="_blank">
                <span
                  class="more uppercase nav nav_go nav_go_s nav_go_right out"
                  :class="{ mobile: mobile }"
                >
                  Online
                </span>
              </a>
              <span
                v-else-if="event.canceled"
                class="more uppercase nav nav_go nav_go_s nav_go_right out"
                :class="{ mobile: mobile }"
              >
                Abgesagt
              </span>
              <a
                v-if="time && !event.online && !event.canceled"
                :href="icsUrl"
                target="_blank"
              >
                <span class="more uppercase nav nav_go nav_go_s nav_go_right"
                  >Merken</span
                ></a
              >
              <!--    <span class="more uppercase nav nav_go nav_go_s nav_go_right"
                >Teilen</span$
              > -->
              <router-link
                v-if="event.registration && !secondColumn"
                :to="'#register'"
                @click="scrollTo('#register')"
              >
                <span
                  v-if="event.registration && !secondColumn"
                  class="more uppercase nav nav_go nav_go_s nav_go_right"
                  :class="{ out: event.max_visitors < 1 }"
                >
                  {{ anmelden }}</span
                >
              </router-link>
              <div></div>
              <h2 v-if="hasContributors" class="subtitle">
                Referentinnen und Referenten
              </h2>
              <Contributors
                v-if="hasContributors"
                :contributors="event.contributors"
              />
              <div v-if="!event.online && !event.canceled" class="place">
                <h2 class="subtitle">Veranstaltungsort</h2>
                <p>{{ address }}</p>
                <span class="small">
                  <a :href="mapUrl" class="maps link" target="_blank"
                    >In Google Maps öffnen</a
                  >
                </span>
              </div>
              <div v-else-if="event.online">
                <h2 class="subtitle">Veranstaltungsort</h2>
                <p>
                  Die Veranstaltung findet ohne Besucher statt. Wir zeichnen
                  diese auf und werden das Video auf unserem
                  <a
                    class="y link"
                    href="https://www.youtube.com/channel/UC5GRB0GWq9MD-yua_mQAwgQ"
                    target="_blank"
                    >YouTube Kanal</a
                  >
                  veröffentlichen.
                </p>
              </div>
            </div>
            <div v-if="hasImage" class="margin"></div>
          </div>
          <div
            class="content right"
            :class="{
              right: secondColumn,
              translate_down: !hasImage,
              mobile: mobile
            }"
          >
            <div class="column">
              <a v-if="!secondColumn && available" id="register"></a>
              <Register v-if="event.registration && available" :event="event" />
              <span
                v-else-if="event.registration && !available && secondColumn"
                class="more uppercase nav nav_go nav_go_s nav_go_right out"
                >ausgebucht</span
              >
            </div>
          </div>
        </div>
        <div v-if="!secondColumn" class="fill_middle"></div>
      </div>
      <div class="end">
        <svg
          class="end"
          :class="{ img_container: hasImage }"
          viewBox="0 0 423.16 626.63"
        >
          <polygon
            points="423.16 626.63 0 0 423.16 0 423.16 626.63"
            :fill="fill"
          />
        </svg>
        <div
          class="fill_end"
          :class="{ img_container: hasImage, mobile: mobile }"
        >
          <img v-if="hasImage" class="img" :src="imageUrl" />
        </div>
      </div>
    </div>
  </div>
  <div v-else></div>
</template>

<script>
import Contributors from "../components/Contributors";
import Register from "../components/Register";

import moment from "moment";

export default {
  name: "Event",
  components: { Contributors, Register },
  props: {},
  data() {
    return {
      types: {
        1: "Gesundheitsforum",
        2: "Thementag",
        3: "Spezial",
        4: "Konzert"
      }
    };
  },
  computed: {
    event() {
      if (this.$route.params.id) {
        return this.$store.getters.eventById(this.$route.params.id);
      } else {
        return this.$store.getters.eventByWebId(this.$route.params.webid);
      }
      //  console.log(this.$route.params.data);
      //  return this.$route.params.data;
    },
    loading() {
      return this.$store.getters.eventsLoading;
    },
    ready() {
      return this.$store.getters.eventsLoaded;
    },
    mobile() {
      return this.$store.getters.mobile;
    },
    small() {
      return this.$store.getters.break3;
    },
    secondColumn() {
      return this.$store.getters.secondColumn;
    },
    hasContributors() {
      return this.event.contributors.length > 0;
    },
    hasImage() {
      return this.event.image == 1;
    },
    fill() {
      if (this.event.type == 2) {
        return "#eb7a14";
      } else if (this.event.type > 2) {
        return "#bd388d";
      }
      return "#b9b955";
    },
    date() {
      return moment(this.event.date, "YYYY-MM-DD").format("dd D.M.");
    },
    time() {
      if (this.event.time == "00:00:00") {
        return "";
      }
      if (this.event.time) {
        if (moment(this.event.time, "HH-mm-ss").format("m") != 0) {
          return moment(this.event.time, "HH-mm-ss").format("H:mm") + " Uhr";
        }
        return moment(this.event.time, "HH-mm-ss").format("H") + " Uhr";
      }
      return "";
    },
    month() {
      return this.$store.getters.months[
        moment(this.event.date, "YYYY-MM-DD").format("M") - 1
      ];
    },
    address() {
      return (
        this.event.plz +
        " " +
        this.event.place +
        ", " +
        this.event.address +
        (this.event.address_description == ""
          ? ""
          : ", " + this.event.address_description)
      );
    },
    imageUrl() {
      if ("images" in this.event && this.event.image == 1) {
        if (this.event.images.length > 0) {
          return (
            this.$store.state.connection +
            "/storage/images/" +
            this.event.images[0].link_name +
            "_500.jpg"
          );
        } else {
          return "";
        }
      }
      return "";
    },
    imageUrlOg() {
      if ("images" in this.event && this.event.image == 1) {
        if (this.event.images.length > 0) {
          return (
            this.$store.state.connection +
            "/storage/images/" +
            this.event.images[0].link_name +
            "_800.jpg"
          );
        } else {
          return "";
        }
      }
      return "";
    },
    icsUrl() {
      return (
        this.$store.state.connection +
        "/storage/files/ics/" +
        this.event.web_id +
        ".ics"
      );
    },
    mapUrl() {
      const address = encodeURI(
        this.event.address + "," + this.event.plz + "," + this.event.address
      );
      return "https://www.google.com/maps/search/?api=1&query=" + address;
    },
    available() {
      return this.event.max_visitors > 0;
    },
    anmelden() {
      return this.event.max_visitors < 1 ? "ausgebucht" : "anmelden";
    },
    youtube() {
      if (this.event.link != "") {
        return this.event.link;
      }
      return "https://www.youtube.com/channel/UC5GRB0GWq9MD-yua_mQAwgQ";
    },
    isLivestream() {
      return this.event.link.includes("embed.upstream-cloud.ch");
    }
  },
  created() {
    if (!this.ready && !this.loading) {
      this.$store.dispatch("loadEvents");
    }
  },
  methods: {
    scrollTo: function(hashtag) {
      setTimeout(() => {
        location.href = hashtag;
      }, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/_variables.scss";
/*

ONLY SINGLE PAGE

*/
.video {
  margin-top: 3.5rem;
  width: 100%;
  padding-top: 66%;
  position: relative;
}
.youtube {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  right: 0;
}

div.bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
div.left {
  position: relative;
}
div.content_container {
  position: relative;
  display: flex;
}
div.container {
  width: 100%;
}
div.margin {
  width: 100%;
  height: 4rem;
}
.img {
  object-fit: cover;
  background: $mainBlue;
  height: 51.5rem;
  width: auto;
  //  width: 40.5rem;
  transform: translate(-17rem);
}
#register {
  opacity: 0;
  position: absolute;
  transform: translateY(-17.5rem);
}
.middle {
  position: absolute;
  width: 100%;
  top: calc(31rem - 1px);
  bottom: -1px;
}
.second_column {
  width: 73.5rem;
}
.fill_end {
  position: absolute;
  left: 31rem;
  top: 0;
  bottom: 0;
  right: 0;
}
.fill_end.mobile {
  right: -19rem;
}
.fill_middle {
  position: absolute;
  left: 94rem;
  top: 0;
  bottom: 0;
  right: 0;
}
div.place {
}
.flex {
  display: flex;
}
.column {
  flex-direction: column;
}

/*

    TO CHECK:

*/

/*



*/
.router-link-active {
  text-decoration: none;
  color: $mainBlue;
}
a {
  color: $mainBlue;
}

.event {
  display: flex;
  flex-direction: row;
  padding-top: 3rem;
  width: 100%;
  min-height: calc(100% - 18rem);
}
.event.mobile {
  overflow-x: hidden;
  margin-left: -19rem;
  width: calc(100% + 19rem);
}
div.start {
  display: flex;
  justify-content: flex-end;
}
div.end {
  position: relative;
}
.date {
  position: absolute;
  z-index: 0;
}

.date,
.content {
  color: $mainBlue;
}
.date {
  padding: 3rem 0 0rem 0;
}
svg.start {
  height: 31rem;
  transform: translate(1px, 0);
}
svg.end {
  /*  height: 55rem; */
  width: 31rem;
  transform: translate(0, 0);
}
.content.left {
  padding: 3rem 3rem 0 0;
  width: calc(100% - 3rem);
  max-width: 102.4rem;
  min-width: 67rem;
  z-index: 1;
}
.content.right {
  padding: 3rem 3rem 0 0;
  width: calc(100% - 3rem);
  max-width: 102.4rem;
  min-width: 67rem;
  z-index: 1;
}

/*
.content::before {
  margin-top: -3rem;
}*/
.date {
  width: 21rem;
  color: #fff;
}

.bg {
  background: $bgGreen;
}
.bg.special {
  background: $bgLila;
}
.bg.theme {
  background: $bgOrange;
}
.bg.blue {
  background: #0a268a;
}
a.y {
  text-decoration: underline;
}
.event > .container > .content_container > .content,
.event > .container > .content_container > .fill_middle,
.event > .container > .content_container > .left > .middle,
.event > .container > .content_container > .second_column,
.event > .container > .end > .fill_end {
  background: #b9b955;
}
.event.special > .container > .content_container > .content,
.event.special > .container > .content_container > .fill_middle,
.event.special > .container > .content_container > .left > .middle,
.event.special > .container > .content_container > .second_column,
.event.special > .container > .end > .fill_end {
  background: #bd388d;
}
.event.theme > .container > .content_container > .content,
.event.theme > .container > .content_container > .fill_middle,
.event.theme > .container > .content_container > .left > .middle,
.event.theme > .container > .content_container > .second_column,
.event.theme > .container > .end > .fill_end {
  background: #eb7a14;
}

.content {
  width: 100%;
  height: auto;
}

.nav_go:before {
  background-color: $mainBlue;
}
.content.mobile > .nav_go::before {
  background-color: #fff;
}

.translate_down {
  transform: translate(0, 3rem);
}
.content.left.translate_down {
  padding: 0;
  padding-right: 3rem;
}
.content.mobile {
  min-width: auto;
}
.column.mobile {
  max-width: 90%;
}
svg.end.img_container {
  width: 38.2rem;
}
.fill_end.img_container {
  left: 38.2rem;
}
p.info {
  font-style: italic;
}
.link {
  text-decoration: underline;
}
</style>
