import { render } from "./manageImages.vue?vue&type=template&id=78d4f2d7&scoped=true"
import script from "./manageImages.vue?vue&type=script&lang=js"
export * from "./manageImages.vue?vue&type=script&lang=js"

import "./manageImages.vue?vue&type=style&index=0&id=78d4f2d7&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-78d4f2d7"
/* hot reload */
if (module.hot) {
  script.__hmrId = "78d4f2d7"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('78d4f2d7', script)) {
    api.reload('78d4f2d7', script)
  }
  
  module.hot.accept("./manageImages.vue?vue&type=template&id=78d4f2d7&scoped=true", () => {
    api.rerender('78d4f2d7', render)
  })

}

script.__file = "src/views/manageImages.vue"

export default script