import { createStore } from "vuex";
import axios from "axios";
import moment from "moment";

export default createStore({
  state: {
    user: null,
    lang: "de",
    resetEmail: null,
    acceptedCookies: false,
    events: [],
    eventsLoading: false,
    eventsLoaded: false,
    story: [],
    storyLoading: false,
    storyLoaded: false,
    decades: [1920, 1930, 1940, 1950, 1960, 1970, 1980, 1990, 2000, 2010, 2020],
    months: [
      "jan",
      "feb",
      "mar",
      "apr",
      "mai",
      "jun",
      "jul",
      "aug",
      "sep",
      "okt",
      "nov",
      "dez"
    ],
    house: {
      1: "Lukas Klinik",
      2: "Wegman Klinik",
      3: "Klinik Arlesheim"
    },
    filter: 0,
    contributors: [],
    width: Number,
    registered: [],
    hidefirst: false,
    archive: false,
    connection:
      process.env.NODE_ENV === "development"
        ? "http://klinikarlesheim.test"
        : "https://api.100.klinik-arlesheim.ch"
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      state.user = userData;
      state.resetEmail = null;
      localStorage.setItem("user", JSON.stringify(userData));
      axios.defaults.headers.common["Authorization"] = `Bearer ${
        userData.token
      }`;
    },
    CLEAR_USER_DATA() {
      localStorage.removeItem("user");
      location.reload();
    },
    changeLang(state, lang) {
      state.lang = lang;
    },
    acceptCookies(state) {
      localStorage.acceptedCookies = 1;
      state.acceptedCookies = true;
    },
    setEvents(state, events) {
      state.events = events;
      state.eventsLoading = false;
      state.eventsLoaded = true;
    },
    setStory(state, story) {
      state.story = story;
      state.storyLoading = false;
      state.storyLoaded = true;
    },
    resetRequest(state, email) {
      state.resetEmail = email;
    },
    addEvent(state, event, id) {
      event.id = id;
      state.events.push(event);
    },
    addStory(state, story, id) {
      story.id = id;
      state.story.push(story);
    },
    changeEvent(state, event) {
      let e = state.events.find(e => e.id === event.id);
      let index = state.events.indexOf(e);
      state.events[index] = event;
    },
    changeStory(state, story, id) {
      let s = state.story.find(s => s.id === id);
      let index = state.story.indexOf(s);
      state.story[index] = story;
    },
    deleteEvent(state, id) {
      let e = state.events.find(e => e.id === id);
      let index = state.events.indexOf(e);
      state.events.splice(index, 1);
    },
    deleteStory(state, id) {
      let s = state.story.find(s => s.id === id);
      let index = state.story.indexOf(s);
      state.story.splice(index, 1);
    },
    addContributor(state, contributor, id) {
      contributor.id = id;
      state.contributors.push(contributor);
    },
    changeContributor(state, contributor, id) {
      let index = state.contributors.find(c => c.id === id);
      state.contributors[index] = contributor;
    },
    deleteContributor(state, id) {
      let c = state.contributors.find(c => c.id === id);
      let index = state.contributors.indexOf(c);

      state.contributors.splice(index, 1);
    },
    setWindowSize(state, w) {
      state.width = w;
    },
    setFilter(state, filter) {
      state.filter = filter;
    },
    uploadImage(state, img) {
      console.log(img);
    },
    hideEvents(state, bool) {
      state.hidefirst = bool;
    },
    showArchive(state, bool) {
      state.archive = bool;
    },
    register(state, event) {
      state.registered.push(event);
    }
  },
  actions: {
    sendRegistration({ commit }, registration) {
      return axios
        .post(this.state.connection + "/api/sendRegistration", registration)
        .then(({ data }) => {
          if (data.registered == 1) {
            commit("register", registration.event);
          }
          return data;
        });
    },
    loadEvents({ commit }) {
      this.state.eventsLoading = true;
      return axios
        .get(this.state.connection + "/api/getEvents")
        .then(({ data }) => {
          commit("setEvents", data);
        });
    },
    loadStory({ commit }) {
      this.state.storyLoading = true;
      return axios
        .get(this.state.connection + "/api/getStory?l=" + this.state.lang)
        .then(({ data }) => {
          commit("setStory", data);
        });
    },
    register({ commit }, credentials) {
      return axios
        .post(this.state.connection + "/api/register", credentials)
        .then(({ data }) => {
          commit("SET_USER_DATA", data);
        });
    },
    login({ commit }, credentials) {
      return axios
        .post(this.state.connection + "/api/login", credentials)
        .then(({ data }) => {
          commit("SET_USER_DATA", data);
        });
    },
    logout({ commit }) {
      commit("CLEAR_USER_DATA");
    },
    resetRequest({ commit }, credentials) {
      return axios
        .post(this.state.connection + "/api/reset-request", credentials)
        .then(() => {
          commit("resetRequest", credentials.email);
        });
    },
    resetPassword({ commit }, credentials) {
      return axios
        .post(this.state.connection + "/api/reset", credentials)
        .then(({ data }) => {
          commit("SET_USER_DATA", data);
        });
    },
    addEvent({ commit }, event) {
      return axios
        .post(this.state.connection + "/api/addEvent", event)
        .then(({ data }) => {
          commit("addEvent", event, data.id);
          return data.id;
        });
    },
    addStory({ commit }, story) {
      return axios
        .post(this.state.connection + "/api/addStory", story)
        .then(({ data }) => {
          commit("addStory", story, data.id);
          return data.id;
        });
    },
    changeEvent({ commit }, event) {
      return axios
        .put(this.state.connection + "/api/changeEvent", event)
        .then(() => {
          commit("changeEvent", event);
        });
    },
    changeStory({ commit }, story) {
      return axios
        .put(this.state.connection + "/api/changeStory", story)
        .then(() => {
          commit("changeStory", story, story.id);
        });
    },
    deleteEvent({ commit }, id) {
      return axios
        .delete(this.state.connection + "/api/deleteEvent", { data: id })
        .then(() => {
          commit("deleteEvent", id);
        });
    },
    deleteStory({ commit }, id) {
      return axios
        .delete(this.state.connection + "/api/deleteStory", { data: id })
        .then(() => {
          commit("deleteStory", id);
        });
    },
    addContributor({ commit }, contributor) {
      return axios
        .post(this.state.connection + "/api/addContributor", contributor)
        .then(({ data }) => {
          commit("addContributor", contributor, data.id);
          return data.id;
        });
    },
    changeContributor({ commit }, contributor) {
      return axios
        .put(this.state.connection + "/api/changeContributor", contributor)
        .then(() => {
          commit("changeContributor", contributor, contributor.id);
        });
    },
    deleteContributor({ commit }, id) {
      return axios
        .delete(this.state.connection + "/api/deleteContributor", { data: id })
        .then(() => {
          commit("deleteContributor", id);
        });
    },
    uploadImage({ commit }, credentials) {
      //TODO: use it
      return axios
        .delete(this.state.connection + "/api/uploadImage", credentials)
        .then(() => {
          commit("uploadImage", credentials);
        });
    }
  },
  getters: {
    events(state) {
      if (state.filter == 0) {
        return state.events;
      } else {
        return state.events.filter(event => event.type == state.filter);
      }
    },
    EventsShown(state, getters) {
      return state.hidefirst
        ? getters.upcomingEvents.slice(2)
        : getters.upcomingEvents;
    },
    FirstEvent(state, getters) {
      return getters.upcomingEvents.shift();
    },
    upcomingEvents(state, getters) {
      let upcomming = getters.events.filter(
        event =>
          moment(event.date, "YYYY-MM-DD").format("YYYY-MM-DD") >=
          moment().format("YYYY-MM-DD")
      );
      return getters.ongoingEvents.concat(upcomming);
    },
    currentEvents: (state, getters) => n => getters.upcomingEvents.slice(0, n),
    passedEvents(state, getters) {
      return getters.events.filter(
        event =>
          moment(event.date, "YYYY-MM-DD").format("YYYY-MM-DD") <
          moment().format("YYYY-MM-DD")
      );
    },
    ongoingEvents(state, getters) {
      return getters.passedEvents.filter(
        event =>
          moment(event.date_end, "YYYY-MM-DD").format("YYYY-MM-DD") >=
          moment().format("YYYY-MM-DD")
      );
    },
    eventsByMonths: (state, getters) => m => {
      if (state.archive) {
        return getters.passedEvents.filter(
          event =>
            parseInt(moment(event.date, "YYYY-MM-DD").format("M")) ==
            parseInt(m)
        );
      } else {
        return getters.EventsShown.filter(
          event =>
            parseInt(moment(event.date, "YYYY-MM-DD").format("M")) ==
            parseInt(m)
        );
      }
    },
    house(state) {
      return state.house;
    },
    story(state) {
      return state.story;
    },
    storyHome(state) {
      return state.story.filter(story => story.featured == 1);
    },
    storyById: state => id => {
      return state.story.filter(story => story.id == id)[0];
    },
    storyByWebId: state => webId => {
      return state.story.filter(story => story.web_id == webId)[0];
    },
    eventById: state => id => {
      return state.events.filter(event => event.id == id)[0];
    },
    eventByWebId: state => webId => {
      return state.events.filter(event => event.web_id == webId)[0];
    },
    eventsLoading(state) {
      return state.eventsLoading;
    },
    decades(state) {
      return state.decades;
    },
    eventsLoaded(state) {
      return state.eventsLoaded;
    },
    storyLoading(state) {
      return state.storyLoading;
    },
    storyLoaded(state) {
      return state.storyLoaded;
    },
    months(state) {
      return state.months;
    },
    loggedIn(state) {
      return !!state.user;
    },
    resetEmail(state) {
      return state.resetEmail;
    },
    width(state) {
      return state.width;
    },
    secondColumn(state) {
      return state.width >= 1440;
    },
    mobile(state) {
      return state.width < 800;
    },
    break1(state) {
      return state.width < 1170;
    },
    break2(state) {
      return state.width < 1040;
    },
    break3(state) {
      return state.width < 850;
    },
    registered(state) {
      return state.registered;
    },
    lang(state) {
      return state.lang;
    },
    en(state) {
      return state.lang == "en";
    },
    acceptedCookies(state) {
      if (localStorage.acceptedCookies == 1) {
        return true;
      }
      return state.acceptedCookies;
    }
  }
});
