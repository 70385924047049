/* eslint no-multiple-template-root: 0 */
<template>
  <div id="lang" class="center">
    <div class="item button">
      <span class="lang" :class="{ active: !en }" @click="changeLang(false)"
        >Deutsch</span
      >
      |
      <span class="lang" :class="{ active: en }" @click="changeLang(true)"
        >Englisch</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    en: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:modelValue"],
  methods: {
    changeLang(event) {
      this.$emit("update:modelValue", event);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../scss/_variables.scss";
@import "../scss/_admin.scss";
span.active {
  color: white !important;
}
span.lang {
  cursor: pointer;
}
div#lang > .button {
  font-size: 2rem;
}
div#lang > .button > span {
  color: #0a268a;
}
</style>
