<template>
  <div v-if="ready">
    <router-link to="/100">
      <span class="more uppercase nav nav_go nav_go_s nav_go_right"
        >Vorschau</span
      >
    </router-link>
    <div id="new">
      <div class="item button " @click="editStory(0, $event)">
        <span class="more uppercase nav nav_go nav_go_s nav_go_right">
          neuer Storypunkt erstellen</span
        >
      </div>
    </div>
    <div class="manage_story">
      <div
        v-for="s in story"
        :key="s.id"
        class="click item"
        :class="{ bg1: s.house == 1, bg2: s.house == 2 }"
        @click="editStory(s.id, $event)"
      >
        <div>
          <h1 class="title">
            {{ year(s.year) }}
          </h1>
          <h2 class="subtitle">{{ s.title }}</h2>
        </div>
        <!--      <Event :key="story.id" :event="story" />-->
        <img
          v-if="s.image != undefined"
          class="s_img"
          :src="
            $store.state.connection +
              '/storage/images/' +
              s.image.link_name +
              '_200.jpg'
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    ready: function() {
      return this.$store.getters.storyLoaded;
    },
    loading() {
      return this.$store.getters.storyLoading;
    },
    story() {
      return this.$store.getters.story;
    },
    lang() {
      return this.$store.getters.lang;
    }
  },
  created() {
    if (!this.ready && !this.loading) {
      this.$store.dispatch("loadStory");
    }
  },
  methods: {
    editStory: function(id, e) {
      e.preventDefault();
      e.stopPropagation();
      this.$router.push({
        name: "editStory",
        params: {
          id: id
        }
      });
    },
    year(y) {
      return moment(y, "YYYY-MM-DD").format("MMMM YYYY");
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../scss/_variables.scss";
@import "../scss/_admin.scss";
#new {
  margin-bottom: 2rem;
}
.manage_story {
  margin: 1rem;
}
.s_img {
  position: relative;
}
.item {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.bg1 {
  background: $green;
}
.bg2 {
  background: $orange;
}
.bg3 {
  background: $lila;
}
.more {
  color: white;
  margin-left: 2rem;
}
.more::before {
  background: white;
}
</style>
