<template>
  <div>
    <h1 class="subtitle">Dashboard</h1>
    <router-link to="manageEvents">
      <h1 class="title">Events vewalten</h1>
    </router-link>
    <router-link to="manageContributors">
      <h1 class="title">Referenten vewalten</h1>
    </router-link>
    <router-link to="manageStory">
      <h1 class="title">Geschichte vewalten</h1>
    </router-link>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      isLoading: true,
      data: []
    };
  },
  created() {
    axios
      .get(this.$store.state.connection + "/api/dashboard")
      .then(({ data }) => {
        this.data = data;
        this.isLoading = false;
      });
  }
};
</script>
