import { render } from "./ManageStory.vue?vue&type=template&id=73295246&scoped=true"
import script from "./ManageStory.vue?vue&type=script&lang=js"
export * from "./ManageStory.vue?vue&type=script&lang=js"

import "./ManageStory.vue?vue&type=style&index=0&id=73295246&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-73295246"
/* hot reload */
if (module.hot) {
  script.__hmrId = "73295246"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('73295246', script)) {
    api.reload('73295246', script)
  }
  
  module.hot.accept("./ManageStory.vue?vue&type=template&id=73295246&scoped=true", () => {
    api.rerender('73295246', render)
  })

}

script.__file = "src/views/ManageStory.vue"

export default script