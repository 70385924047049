<template>
  <div id="delete">
    <div v-if="event.id > 0" class="item button" @click="deleteEvent">
      <span class="more uppercase nav nav_go nav_go_s nav_go_right "
        >löschen</span
      >
    </div>
  </div>
  <div v-if="!isLoading && small" class="preview p">
    <Event :event="event" />
  </div>
  <div class="admin_center" :class="{ p: !small }">
    <div v-if="!isLoading" id="editEvent" class="edit">
      <div>
        <div class="item">
          <form class="e">
            <div>
              <BaseInput v-model="event.title" label="Titel*" type="text" />
              <BaseInput
                v-model="event.subtitle"
                label="Beschreibung"
                type="text"
              />
              <BaseInput v-model="event.info" label="Info" type="text" />
              <BaseTextarea v-model="event.text" label="Text*" />
            </div>
            <div>
              <BaseInput v-model="event.date" label="Datum*" type="date" />
              <!--             <BaseCheckbox v-model="multiday" label="Mehrtägiger Event?" />
              <BaseInput
                v-if="multiday"
                v-model="event.date_end"
                label="End-Datum"
                type="date"
              />-->
              <BaseInput v-model="event.time" label="Uhrzeit" type="time" />
              <BaseInput v-model="event.address" label="Adresse*" type="text" />
              <BaseInput v-model="event.plz" label="PLZ*" type="number" />
              <BaseInput v-model="event.place" label="Ort*" type="text" />
              <BaseInput
                v-model="event.address_description"
                label="Zusatz"
                type="text"
              />
              <BaseCheckbox
                v-model="event.registration"
                label="Anmeldung möglich?"
              />
              <BaseInput
                v-if="event.registration"
                v-model="event.max_visitors"
                label="Maximale Anzahl Teilnehmer"
                type="number"
              />
              <BaseCheckbox v-model="event.online" label="Online-Event" />
              <BaseCheckbox v-model="event.canceled" label="Event abgesagt" />
              <label>Art*</label>
              <select v-model="event.type" class="dropdown" name="amount">
                <option value="1">Gesundheitsforum</option>
                <option value="2">Thementag</option>
                <option value="3">Spezial</option>
                <option value="4">Konzert</option>
              </select>
              <BaseInput v-model="event.link" label="Link" type="text" />
              <BaseInput
                v-model="event.link_name"
                label="Link-Name (angezeigter Text)"
                type="text"
              />
              <BaseCheckbox v-model="hasImg" label="Bild" />
            </div>
          </form>
        </div>
        <div
          v-if="hasImg"
          class="item button"
          :class="{ mainb: !imageSet }"
          @click="openImgLayer"
        >
          <span class="more uppercase nav nav_go nav_go_s nav_go_right "
            >Bild {{ imageSet ? "ändern" : "hinzufügen" }}</span
          >
        </div>
        <div
          class="item button"
          :class="{ mainb: imageSet }"
          @click="checkForm"
        >
          <span class="more uppercase nav nav_go nav_go_s nav_go_right "
            >Speichern</span
          >
        </div>
        <div v-if="success" class="success item">
          <span class="small">
            {{ notifications.success[0] }}
          </span>
        </div>
        <div v-else class="error item">
          <span
            v-for="(e, key) in notifications.errors"
            :key="key"
            class="small"
          >
            {{ e }}
          </span>
        </div>
      </div>
    </div>
    <div>
      <div>
        <div v-if="!isLoading && !small" class="preview">
          <Event :event="event" />
        </div>
        <div v-if="!isLoading" class="c_container">
          <div class="item in">
            <span class="small">Referent*innen</span>
            <div class="c">
              <div
                v-for="contributor in event.contributors"
                :key="contributor.id"
                class=" click c_item"
                @click="disconnectContributor(contributor)"
              >
                <Contributor :key="contributor.id" :contributor="contributor" />
              </div>
            </div>
          </div>
          <div class="item out">
            <span class="small">Verfügbare Referent*innen</span>
            <div class="c">
              <div
                v-for="contributor in availableContributors"
                :key="contributor.id"
                class=" click c_item"
                @click="connectContributor(contributor)"
              >
                <Contributor :key="contributor.id" :contributor="contributor" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <ChooseImage
      v-if="selectingImage"
      :type="imgType"
      @set-image="setImage($event)"
      @close="closeImgLayer"
    />
  </div>
</template>

<script>
import axios from "axios";
import BaseInput from "../components/BaseInput";
import BaseCheckbox from "../components/BaseCheckbox";
import BaseTextarea from "../components/BaseTextarea";
import Event from "../components/EventPreview";
import Contributor from "../components/Contributor";
import ChooseImage from "../components/ChooseImage";

export default {
  components: {
    BaseInput,
    BaseCheckbox,
    BaseTextarea,
    Event,
    Contributor,
    ChooseImage
  },
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      event: {
        id: 0,
        title: "",
        subtitle: "",
        info: "",
        text: "",
        date: "",
        date_end: "",
        plz: "",
        address: "",
        address_description: "",
        place: "",
        time: "",
        type: "",
        image: 0,
        images: [],
        image_id: 0,
        registration: 0,
        online: 0,
        canceled: 0,
        max_visitors: 0,
        contributors: [],
        link_name: "",
        link: ""
      },
      contributors: [],
      multiday: false,
      isLoading: true,
      notifications: { errors: [], success: [] },
      selectingImage: false,
      image: 0,
      imgType: 1
    };
  },
  computed: {
    availableContributors() {
      const self = this;
      //    return self.contributors.filter(
      //      el => self.event.contributors.indexOf(el) === -1
      //    );
      /*  return self.contributors.filter(function(item) {
        return !self.event.contributors.indexOf(item);
      });*/
      return self.contributors.filter(el => {
        return !self.event.contributors.includes(el);
      });

      //  return c;
      //return this.contributors.filter(c => c.active);
    },
    eventContributors() {
      const self = this;
      return self.contributors.filter(
        el => self.event.contributors.indexOf(el) === -1
      );
      //return this.contributors.filter(c => c.active);
    },
    hasImg: {
      get: function() {
        return this.event.image == 1;
      },
      // setter
      set: function(bool) {
        this.event.image = bool ? 1 : 0;
      }
    },
    imageSet() {
      return (
        this.event.image_id > 0 ||
        ("images" in this.event && this.event.images.length > 0)
      );
    },
    small() {
      return this.$store.getters.break1;
    },
    error() {
      return this.notifications.errors.length > 0;
    },
    success() {
      return this.notifications.success.length > 0;
    }
  },
  created() {
    if (this.id === 0) {
      this.isLoading = false;
    } else {
      this.getEvent();
    }
    this.getContributors();
  },
  methods: {
    getEvent() {
      if (this.id == 0) {
        this.isLoading = false;
      } else {
        axios
          .get(this.$store.state.connection + "/api/getEvent?id=" + this.id)
          .then(({ data }) => {
            this.event = data;
            if (this.event.date_end != this.event.date) {
              this.multiday = true;
            }
            this.isLoading = false;
          });
      }
    },
    getContributors() {
      axios
        .get(
          this.$store.state.connection +
            "/api/getContributors?event_id=" +
            this.id
        )
        .then(({ data }) => {
          this.contributors = data;
        });
    },
    setImage(e) {
      this.event.img = 1;
      let images = [{ link_name: e.link }];

      this.event.image_id = e.id;
      this.event.images = images;
      this.selectingImage = false;
    },
    openImgLayer(e) {
      e.preventDefault();
      this.selectingImage = true;
    },
    closeImgLayer() {
      this.selectingImage = false;
    },
    checkForm(e) {
      e.preventDefault();
      //TODO: validate
      if (
        !this.event.title ||
        !this.event.text ||
        !this.event.date ||
        (this.multiday && !this.event.date_end) ||
        !this.event.type ||
        !this.event.place ||
        !this.event.plz ||
        !this.event.address
      ) {
        this.notifications.errors = [];
        this.notifications.errors.push("Bitte alle Felder ausfüllen");
        console.log(this.notifications.errors);
      } else {
        if (!this.multiday) {
          this.event.date_end = this.event.date;
        }
        //TODO: check for errors
        if (this.event.id > 0) {
          this.changeEvent();
        } else {
          this.addEvent();
        }
      }
    },
    changeEvent() {
      if (!this.hasImg) {
        this.event.image_id = 0;
      } else {
        if (this.event.images.length > 0) {
          if ("id" in this.event.images[0]) {
            this.event.image_id = this.event.images[0].id;
          }
        }
      }
      if (!this.event.registration) {
        this.event.max_visitors = 0;
      }
      this.$store
        .dispatch("changeEvent", this.event)
        .then(() => {
          this.notifications.success.push("Änderung gespeichert");

          //   this.$router.push({ name: "manageEvents" });
        })
        .catch(err => {
          if (err.response.status === 401) {
            this.notifications.errors = [];
            this.notifications.errors.push("keine Berechtigung");
          } else {
            this.notifications.errors.push(err.error);
          }
        });
    },
    addEvent() {
      const self = this;
      this.$store
        .dispatch("addEvent", this.event)
        .then(res => {
          self.event.id = res;
          this.notifications.success.push("Event erstellt");

          //   this.$router.push({ name: "manageEvents" });
        })
        .catch(err => {
          if (err.response.status === 401) {
            this.notifications.errors = [];
            this.notifications.errors.push("keine Berechtigung");
          } else {
            this.notifications.errors.push(err.error);
          }
        });
    },
    connectContributor(contributor) {
      this.event.contributors.push(contributor);
      let index = this.contributors.findIndex(c => c.id === contributor.id);
      this.contributors.splice(index, 1);

      //c.active = true;
    },
    disconnectContributor(contributor) {
      let index = this.event.contributors.findIndex(
        c => c.id === contributor.id
      );
      this.event.contributors.splice(index, 1);
      if (!this.contributors.find(c => c.id === contributor.id)) {
        this.contributors.push(contributor);
      }
    },
    deleteEvent(e) {
      e.preventDefault();
      this.$store
        .dispatch("deleteEvent", {
          id: this.event.id
        })
        .then(() => {
          this.$router.push({
            name: "manageEvents"
          });
        })
        .catch(err => {
          if (err.response.status === 401) {
            this.notifications.errors = [];
            this.notifications.errors.push("keine Berechtigung");
          } else {
            this.notifications.errors.push(err.error);
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/_variables.scss";
@import "../scss/_admin.scss";

.admin_center {
  justify-content: left;
}
form.e {
  min-width: 39rem;
}
.c_container {
  display: flex;
  flex-direction: column;
}
.c {
  display: flex;
  flex-wrap: wrap;
  min-height: 1rem !important;
}
.in {
  background: #b9b955;
}
.out {
}
.c_item {
  min-width: 10rem;
}
.c_item > .container {
  width: 100%;
}
.preview {
  padding: 1rem;
}
</style>
