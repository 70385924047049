<template>
  <div class="container">
    <div class="contributor">
      <img class="img" :src="image" />
      <div></div>
      <span class="contributor">
        {{ contributor.title }} {{ contributor.name }}
        {{ contributor.surname }}
      </span>
      <br />
      <span class="contributor">{{ contributor.function }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contributor",
  props: {
    contributor: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  computed: {
    image() {
      if (
        "image" in this.contributor &&
        this.contributor.image != null &&
        "link_name" in this.contributor.image
      ) {
        return (
          this.$store.state.connection +
          "/storage/images/" +
          this.contributor.image.link_name +
          "_200.jpg"
        );
      }
      return require("../assets/default.jpg");
    }
  }
};
</script>

<style scoped lang="scss">
@import "../scss/_variables.scss";

.container {
  width: 50%;
}
.contributor {
  max-width: 20rem;
}
.img {
  background: #0a268a;
  width: 15rem;
  height: 18rem;
  margin: 1rem 0;
  object-fit: cover;
}
span.contributor {
  font-size: 2rem;
  line-height: 2.75rem;
}
</style>
