<template>
  <div id="nav">
    <!--    <router-link v-if="loggedIn" to="/dashboard">
      <span class="more uppercase nav nav_go nav_go_s nav_go_right"
        >dashboard</span
      >
    </router-link>-->
    <router-link v-if="loggedIn" to="/manageEvents">
      <span class="more uppercase nav nav_go nav_go_s nav_go_right"
        >Events
      </span>
    </router-link>
    <router-link to="/manageStory">
      <span class="more uppercase nav nav_go nav_go_s nav_go_right"
        >Historischer Teil</span
      >
    </router-link>

    <router-link to="/manageContributors">
      <span class="more uppercase nav nav_go nav_go_s nav_go_right"
        >Referent*innen</span
      >
    </router-link>

    <router-link to="/manageImages">
      <span class="more uppercase nav nav_go nav_go_s nav_go_right"
        >Bilder</span
      >
    </router-link>

    <router-link v-if="!loggedIn" to="/login" class="button">
      Login
    </router-link>

    <span
      v-else
      class="more uppercase nav nav_go nav_go_s nav_go_left logout"
      @click="logout"
      >Log out</span
    >
  </div>
</template>

<script>
import { authComputed } from "../vuex/helpers.js";
export default {
  computed: {
    ...authComputed
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/_variables.scss";
@import "../scss/admin.scss";
.more {
  margin: 0 0rem;
}
#nav {
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  height: 15rem;
  z-index: 2;
}

.nav-welcome {
  margin-left: auto;
  margin-right: 1rem;
  color: white;
}

a {
  font-weight: bold;
  color: #fff;
  margin: auto 0.8em auto 0.4em;
  text-decoration: none;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}

.router-link-exact-active {
  color: white;
  border-bottom: 2px solid #fff;
}

button,
.button {
  margin-left: auto;
  background: white;
  text-decoration: none;
  color: #2c3e50;

  &.router-link-active {
    color: #2c3e50;
  }
}
.nav_go::before {
  background: #fff;
}
.logoutButton {
  cursor: pointer;
}

.nav-welcome + button {
  margin-left: 0;
}
.logout {
  position: absolute;
  right: 0;
}
a {
  font-weight: inherit;
}
</style>
