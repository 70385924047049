<template>
  <div class="register">
    <span class="big">Anmelden</span>
    <span class="small">Anmeldung für {{ types[event.type] }}</span>
    <span class="small">{{ date }}{{ time }}</span>
    <span class="small">{{ address }}</span>
    <span class="small margin_1_t_b">*Plichtfelder</span>
    <form>
      <label class="small" for="name">
        Vorname*
      </label>
      <input v-model="registration.name" type="text" name="name" />
      <label class="small" for="name">
        Name*
      </label>
      <input v-model="registration.surname" type="text" name="surname" />
      <label class="small" for="surname">
        E-Mail*
      </label>
      <input v-model="registration.email" type="email" name="email" />
      <label class="small" for="email">
        Anzahl Personen
      </label>
      <select v-model="registration.amount" nanme="amount">
        <option value="1">1</option>
        <option value="2">2</option>
      </select>
    </form>
    <span
      class="more uppercase nav nav_go nav_go_s nav_go_right"
      @click="checkRegistration"
      >Anmeldung absenden</span
    >

    <span v-if="msg != ''" class="small"> {{ msg }}</span>
    <span v-else-if="sending" class="small">
      <svg
        id="L4"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 52 18"
        enable-background="new 0 0 0 0"
        xml:space="preserve"
        class="waiting"
      >
        <circle fill="#0a268a" stroke="none" cx="6" cy="9" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.1"
          />
        </circle>
        <circle fill="#0a268a" stroke="none" cx="26" cy="9" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.2"
          />
        </circle>
        <circle fill="#0a268a" stroke="none" cx="46" cy="9" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.3"
          />
        </circle>
      </svg>
    </span>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "Register",
  props: {
    event: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      types: {
        1: "Gesundheitsforum",
        2: "Thementag",
        3: "Spezial-Event"
      },
      registration: {
        name: "",
        surname: "",
        email: "",
        amount: "1",
        event: this.event.id,
        eventname: this.event.title,
        type: this.event.type
      },
      sending: false,
      msg: ""
    };
  },
  computed: {
    date() {
      return moment(this.event.date, "YYYY-MM-DD").format("ddd D.M");
    },
    time() {
      if (this.event.time == "00:00:00") {
        return "";
      }
      return ", " + moment(this.event.time, "HH-mm-ss").format("H") + " Uhr";
    },
    address() {
      return (
        this.event.plz +
        " " +
        this.event.place +
        ", " +
        this.event.address +
        (this.event.address_description == ""
          ? ""
          : ", " + this.event.address_description)
      );
    }
  },
  methods: {
    checkRegistration() {
      if (
        this.registration.name != "" &&
        this.registration.surname != "" &&
        this.registration.email != ""
      ) {
        if (
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            this.registration.email
          )
        ) {
          if (this.$store.getters.registered.indexOf(this.event.id) !== -1) {
            this.msg = "Bereis für diesen Event angemeldet ";
          } else {
            this.sendRegistration();
          }
        } else {
          this.msg = "Bitte eine gültige E-Mail-Adresse angeben.";
        }
      } else {
        this.msg = "Bitte alle Felder ausfüllen.";
      }
    },
    sendRegistration() {
      this.sending = true;
      this.$store.dispatch("sendRegistration", this.registration).then(data => {
        this.sending = false;
        if (data.registered == 1) {
          this.msg = "Die Anmeldung wurde gesendet.";
        } else {
          this.msg = "Die Anmeldung ist fehlgeschlagen.";
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/_variables.scss";
form {
  max-width: 100%;
}
.register {
}
.more {
  margin: 1rem 0;
}
.nav_go:before {
  background-color: $mainBlue;
}
</style>
