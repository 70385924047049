import { render } from "./AdminLang.vue?vue&type=template&id=25ca8b5d&scoped=true"
import script from "./AdminLang.vue?vue&type=script&lang=js"
export * from "./AdminLang.vue?vue&type=script&lang=js"

import "./AdminLang.vue?vue&type=style&index=0&id=25ca8b5d&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-25ca8b5d"
/* hot reload */
if (module.hot) {
  script.__hmrId = "25ca8b5d"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('25ca8b5d', script)) {
    api.reload('25ca8b5d', script)
  }
  
  module.hot.accept("./AdminLang.vue?vue&type=template&id=25ca8b5d&scoped=true", () => {
    api.rerender('25ca8b5d', render)
  })

}

script.__file = "src/components/AdminLang.vue"

export default script