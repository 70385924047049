import { createWebHistory, createRouter } from "vue-router";
import Home from "./views/Home.vue";
import Dashboard from "./views/Dashboard.vue";
import Events from "./views/Events.vue";
import Story from "./views/Story.vue";
import StorySingle from "./views/StorySingle.vue";
import EditEvent from "./views/EditEvent.vue";
import EditContributor from "./views/EditContributor.vue";
import ManageEvents from "./views/ManageEvents.vue";
import manageImages from "./views/manageImages.vue";
import ManageStory from "./views/ManageStory.vue";
import editStory from "./views/EditStory.vue";
import ManageContributors from "./views/ManageContributors.vue";
// import RegisterUser from "./views/RegisterUser.vue";
import LoginUser from "./views/LoginUser.vue";
import PasswordResetRequest from "./views/PasswordResetRequest.vue";
import PasswordReset from "./views/PasswordReset.vue";
import Event from "./views/Event.vue";
import Store from "./vuex/store.js";
import { trackRouter } from "vue-gtag-next";

const routes = [
  /*  {
    path: "/",
    name: "home",
    components: {
      default: Home,
      events: Events
    }
  },*/
  {
    path: "/:lang?",
    name: "home",
    component: Home,
    redirect: { name: "story" }
  },
  {
    path: "/:lang?/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: { requiresAuth: true }
  },
  {
    path: "/:lang?/events",
    name: "events",
    component: Events,
    redirect: { name: "story" }
  },
  {
    path: "/:lang?/archive",
    name: "archive",
    component: Events,
    redirect: { name: "story" }
  },
  {
    path: "/:lang?/100",
    name: "story",
    component: Story
  },
  {
    path: "/:lang?/100/:webid",
    name: "jahr",
    component: StorySingle
  },
  {
    path: "/:lang?/event/:webid",
    name: "event",
    component: Event
  },
  {
    path: "/manageEvents",
    name: "manageEvents",
    component: ManageEvents,
    meta: { requiresAuth: true }
  },
  {
    path: "/manageImages",
    name: "manageImages",
    component: manageImages,
    meta: { requiresAuth: true }
  },
  {
    path: "/manageStory",
    name: "manageStory",
    component: ManageStory,
    meta: { requiresAuth: true }
  },
  {
    path: "/editStory/:id",
    name: "editStory",
    component: editStory,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/manageContributors",
    name: "manageContributors",
    component: ManageContributors,
    meta: { requiresAuth: true }
  },
  {
    path: "/editEvent/:id",
    name: "editEvent",
    component: EditEvent,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/editContributor/:id",
    name: "editContributor",
    component: EditContributor,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/login",
    name: "login",
    component: LoginUser
  },
  {
    path: "/reset-request",
    name: "reset-request",
    component: PasswordResetRequest
  },
  {
    path: "/reset",
    name: "reset",
    component: PasswordReset
  }
];
/*

*/
const router = createRouter({
  history: createWebHistory(),
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash, //to.hash.anchor
        behavior: "smooth"
      };
    } else {
      return { left: 0, top: 0 };
    }
  }
});
router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("user");
  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    next("/");
  } else {
    if (Store.getters.lang != to.params.lang && to.params.lang == "en") {
      Store.commit("changeLang", to.params.lang == "en" ? "en" : "de");
    }
    if (Store.getters.lang == "en") {
      if (to.fullPath.startsWith("/en")) {
        next();
      } else {
        next({ path: "/en" + to.fullPath, params: { lang: "en" } });
      }
    } else {
      next();
    }
  }
});
trackRouter(router, {
  template(to) {
    return {
      page_title: to.path
      //      page_path: to.path
    };
  }
});

export default router;
