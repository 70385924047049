import { render } from "./EditContributor.vue?vue&type=template&id=5a89eeb2&scoped=true"
import script from "./EditContributor.vue?vue&type=script&lang=js"
export * from "./EditContributor.vue?vue&type=script&lang=js"

import "./EditContributor.vue?vue&type=style&index=0&id=5a89eeb2&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-5a89eeb2"
/* hot reload */
if (module.hot) {
  script.__hmrId = "5a89eeb2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5a89eeb2', script)) {
    api.reload('5a89eeb2', script)
  }
  
  module.hot.accept("./EditContributor.vue?vue&type=template&id=5a89eeb2&scoped=true", () => {
    api.rerender('5a89eeb2', render)
  })

}

script.__file = "src/views/EditContributor.vue"

export default script