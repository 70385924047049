import { render } from "./EditStory.vue?vue&type=template&id=07f71ac1&scoped=true"
import script from "./EditStory.vue?vue&type=script&lang=js"
export * from "./EditStory.vue?vue&type=script&lang=js"

import "./EditStory.vue?vue&type=style&index=0&id=07f71ac1&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-07f71ac1"
/* hot reload */
if (module.hot) {
  script.__hmrId = "07f71ac1"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('07f71ac1', script)) {
    api.reload('07f71ac1', script)
  }
  
  module.hot.accept("./EditStory.vue?vue&type=template&id=07f71ac1&scoped=true", () => {
    api.rerender('07f71ac1', render)
  })

}

script.__file = "src/views/EditStory.vue"

export default script