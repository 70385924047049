<template>
  <div>
    <div id="nav" :class="{ mobile: mobile }">
      <div
        id="logo_container"
        :class="{ mobile: mobile, show: !start, hide: start }"
      >
        <router-link to="/">
          <img
            v-if="!mobile || isHome"
            class="logo_100 click"
            src="../assets/KLA_Logo_100_Jahre.jpg"
            :class="{
              mobile: mobile,
              small: !isHome || start,
              show: !start,
              hide: start
            }"
            alt="100 Jahre Klinik Arlesheim"
          />
        </router-link>
        <svg
          v-if="isHome"
          id="logo_100_bg"
          ref="animate"
          viewBox="0 0 276.91 237.03"
          :class="{ mobile: mobile }"
        >
          <polygon points="" fill="#fff">
            <animate
              attributeName="points"
              dur="0.5s"
              fill="freeze"
              :from="from"
              :to="to"
              :begin="begin"
            />
          </polygon>
        </svg>
        <svg
          v-else-if="!mobile"
          id="logo_100_bg"
          viewBox="0 0 276.91 150"
          :class="{ mobile: mobile }"
        >
          <polygon
            points="0 0 0 150 225.71 150 276.91 62.98 276.91 0 0 0"
            fill="#fff"
          />
        </svg>
      </div>
      <div class="nav_full">
        <div class="left">
          <div id="nav_left" :class="{ mobile: mobile }">
            <div v-if="isHome || isEvents">
              <router-link to="/events">
                <h1 class="nav" :class="{ mobile: mobile }" @click="clickNav">
                  JUBILÄUMS- <br />PROGRAMM&nbsp;2021
                </h1>
              </router-link>
              <router-link
                v-if="hasArchive"
                :to="{ name: 'archive' }"
                @click="clickNav()"
              >
                <h2
                  v-if="!mobile"
                  class="nav nav_go nav_go_s nav_go_down nav_go_menu"
                >
                  Videos
                </h2>
              </router-link>
              <h2
                v-if="!mobile"
                class="nav nav_go nav_go_s nav_go_down nav_go_menu"
                @click="toggleSubmenu('1')"
              >
                ZU MONAT SPRINGEN
              </h2>
              <h2
                v-if="!mobile"
                class="nav nav_go nav_go_s nav_go_down nav_go_menu"
                @click="toggleSubmenu('2')"
              >
                NACH ART FILTERN
              </h2>
            </div>
            <div v-else-if="isEvent"></div>
            <div v-else-if="isHistory">
              <router-link to="/100">
                <h1 class="nav" :class="{ mobile: mobile }" @click="clickNav">
                  <br />
                  {{ en ? "HISTORY" : "GESCHICHTE" }}
                </h1>
              </router-link>
              <h2
                v-if="!mobile"
                class="nav nav_go nav_go_s nav_go_down nav_go_menu"
                @click="toggleSubmenu('3')"
              >
                {{ en ? "GO TO DECADE" : "ZU JAHR SPRINGEN" }}
              </h2>
              <h2 v-if="!mobile" class="nav lang">
                <span
                  class="lang"
                  :class="{ selected: !en }"
                  @click="changeLang('de')"
                  >DE</span
                >
                <span
                  class="lang"
                  :class="{ selected: en }"
                  @click="changeLang('en')"
                  >EN</span
                >
              </h2>
            </div>
            <div v-else-if="isHistorySingle">
              <router-link :to="{ name: 'story', hash: getHash }">
                <h1
                  class="nav nav nav_go nav_go_l nav_go_left nav_go_menu"
                  :class="{ mobile: mobile }"
                  @click="clickNav"
                >
                  ZURÜCK<br />ZUR&nbsp;GESCHICHTE
                </h1>
              </router-link>
            </div>
          </div>
        </div>
        <div class="right" :class="{ fixed: !secondColumn }">
          <div id="nav_right" :class="{ mobile: mobile }">
            <router-link
              v-if="!mobile && !isHistory && !isHistorySingle"
              to="/100"
            >
              <h1
                class="nav nav_go nav_go_m nav_go_right nav_go_menu"
                @click="clickNav"
              >
                ZUR&nbsp;GESCHICHTE
              </h1>
            </router-link>
            <div v-else-if="!mobile"></div>
            <img
              v-else-if="!mobileNav"
              id="menu"
              alt="self-Logo"
              src="../assets/Nav/nav_mobil_menu.svg"
              :class="{ mobile: mobile }"
              @click="openNav"
            />
            <img
              v-else
              id="menu"
              alt="self-Logo"
              src="../assets/Nav/nav_mobil_menu_close.svg"
              :class="{ mobile: mobile }"
              @click="closeNav"
            />
          </div>
          <div v-if="secondColumn" class="logo_kla_bg"></div>
          <img
            v-if="secondColumn"
            class="logo_kla"
            src="../assets/logo_kla.svg"
          />
        </div>
      </div>
    </div>

    <div v-if="submenu && !mobile" id="submenu">
      <div v-if="submenuMode == 1">
        <span v-for="(month, key) in months" :key="key">
          <router-link :to="'#' + month" @click="scrollTo('#' + month)">
            <span class="nav" :class="{ inactive: !hasEvents(key) }">{{
              month
            }}</span>
          </router-link>
        </span>
      </div>
      <div v-else-if="submenuMode == 3">
        <span v-for="(decade, key) in decades" :key="key">
          <router-link :to="'#' + decade" @click="scrollTo('#' + decade)">
            <span class="nav">{{ decade }}</span>
          </router-link>
        </span>
      </div>
      <div v-else>
        <span
          v-for="(type, key) in types"
          :key="key"
          class="nav"
          @click="filter(key)"
        >
          {{ type }}
        </span>
      </div>
    </div>
    <div v-if="mobileNav && mobile" id="mobileNav">
      <div v-if="isHome || isEvents">
        <router-link to="/100">
          <h1
            class="nav nav_go nav_go_m nav_go_right nav_go_menu big"
            @click="clickNav"
          >
            ZUR GESCHICHTE
          </h1>
        </router-link>
        <router-link
          v-if="hasArchive"
          :to="{ name: 'archive' }"
          @click="clickNav()"
        >
          <h2 class="nav nav_go nav_go_s nav_go_down nav_go_menu nm">
            Videos
          </h2>
        </router-link>
        <h2
          class="nav nav_go nav_go_s nav_go_down nav_go_menu nm"
          @click="toggleSubmenu('1')"
        >
          ZU MONAT SPRINGEN
        </h2>
        <div class="nm">
          <span v-for="(month, key) in months" :key="key">
            <router-link :to="'#' + month" @click="scrollTo('#' + month)">
              <span class="nav nm" :class="{ inactive: !hasEvents(key) }">{{
                month
              }}</span>
            </router-link>
          </span>
        </div>
        <h2
          class="nav nav_go nav_go_s nav_go_down nav_go_menu nm"
          @click="toggleSubmenu('2')"
        >
          NACH ART FILTERN
        </h2>
        <div class="nm">
          <span
            v-for="(type, key) in types"
            :key="key"
            class="nav nm"
            @click="filter(key)"
          >
            {{ type }}
          </span>
        </div>
      </div>
      <div v-else>
        <router-link to="/events">
          <h1
            class="nav nav_go nav_go_m nav_go_right nav_go_menu big"
            @click="clickNav"
          >
            {{ en ? "to the program" : "Zum Programm" }}
          </h1>
        </router-link>
        <router-link to="/story">
          <h1
            class="nav nav_go nav_go_l nav_go_down nav_go_menu big"
            :class="{ mobile: mobile }"
            @click="clickNav"
          >
            {{ en ? "HISTORY" : "GESCHICHTE" }}
          </h1>
        </router-link>
        <h2 class="nav nav_go nav_go_s nav_go_down nav_go_menu nm">
          {{ en ? "GO TO DECADE" : "ZU JAHR SPRINGEN" }}
        </h2>
        <div class="nm">
          <span v-for="(decade, key) in decades" :key="key">
            <router-link :to="'#' + decade" @click="scrollTo('#' + decade)">
              <span class="nav nm">{{ decade }}</span>
            </router-link>
          </span>
        </div>
        <h2 class="nav nav_go nav_go_s nav_go_down nav_go_menu nm">
          {{ en ? "LANGUAGE" : "SPRACHE" }}
        </h2>
        <div class="nm">
          <h2 v-if="mobile" class="nav">
            <span
              class="lang"
              :class="{ selected: !en }"
              @click="changeLang('de')"
              >DE</span
            >
            <span
              class="lang"
              :class="{ selected: en }"
              @click="changeLang('en')"
              >EN</span
            >
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { authComputed } from "../vuex/helpers.js";

export default {
  data() {
    return {
      submenu: false,
      mobileNav: false,
      submenuMode: 1,
      types: {
        0: "alle",
        1: "Gesundheitsforum",
        2: "Thementag",
        3: "Spezial"
      },
      scrollPos: 0,
      scrollDir: false,
      showLogo: true
    };
  },
  computed: {
    ...authComputed,
    en() {
      return this.$store.getters.en;
    },
    secondColumn() {
      return this.$store.getters.secondColumn;
    },
    from() {
      return "0 0 0 199.28 225.71 237.03 276.91 150 276.91 0 0 0";
    },
    to() {
      if (this.mobile) {
        return this.start
          ? "0 0 0 199.28 225.71 237.03 276.91 150 276.91 0 0 0"
          : "0 0 0 199.28 225.71 237.03 276.91 150 276.91 0 0 0";
      } else {
        return this.start
          ? "0 0 0 150 225.71 150 276.91 62.98 276.91 0 0 0"
          : "0 0 0 199.28 225.71 237.03 276.91 150 276.91 0 0 0";
      }
    },
    begin() {
      return this.start ? "0" : "0.1";
    },
    mobile() {
      return this.$store.getters.mobile;
    },
    months() {
      return this.$store.getters.months;
    },
    isHome() {
      return this.$route.name == "home";
    },
    isEvents() {
      return this.$route.name == "events";
    },
    isEvent() {
      return this.$route.name == "event" || this.$route.name == "archive";
    },
    isHistory() {
      return this.$route.name == "story";
    },
    isHistorySingle() {
      return this.$route.name == "jahr";
    },
    start() {
      return !this.showLogo;
    },
    getHash() {
      if (this.$route.params.webid != undefined) {
        return "#" + this.$route.params.webid;
      }
      return "";
    },
    decades() {
      return this.$store.getters.decades;
    },
    hasArchive() {
      return this.$store.getters.passedEvents.length > 0;
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    const self = this;
    if (this.$route.hash) {
      setTimeout(() => this.scrollTo(self.$route.hash), 1);
    }
  },
  methods: {
    openNav() {
      this.mobileNav = true;
    },
    closeNav() {
      this.mobileNav = false;
    },
    clickNav() {
      this.mobileNav = false;
      this.submenu = false;
    },
    logout() {
      this.$store.dispatch("logout");
    },
    toggleSubmenu(mode) {
      if (this.submenuMode != mode) {
        this.submenu = true;
        this.submenuMode = mode;
      } else {
        this.submenu = this.submenu ? false : true;
      }
    },
    filter(type) {
      this.submenu = false;
      this.mobileNav = false;

      this.$store.commit("setFilter", type);
    },
    scrollTo: function(hashtag) {
      this.submenu = false;
      this.mobileNav = false;
      setTimeout(() => {
        location.href = hashtag;
      }, 1);
    },
    hasEvents(m) {
      return this.$store.getters["eventsByMonths"](m + 1).length > 0;
    },
    handleScroll() {
      let s = window.scrollY;
      if (s > this.scrollPos) {
        if (this.scrollPos == 0) {
          this.showLogo = false;
          //  let animation = this.$refs.animate;
          //  animation.unpauseAnimations()
        }
        this.scrollDir = "down";
      } else {
        this.scrollDir = "up";
        if (s == 0) {
          this.showLogo = true;
          //  let animation = this.$refs.animate;
        }
      }
      this.scrollPos = s;
    },
    changeLang(l) {
      this.$store.commit("changeLang", l);
      let _lang = l == "en" ? l : "";
      this.$router.push({ name: "story", params: { lang: _lang } });
      this.$store.dispatch("loadStory");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/_variables.scss";
#nav {
  position: fixed;
  width: 100%;
  display: flex;
  align-items: flex-start;
  height: 15rem;
  z-index: 2;
  //  background: linear-gradient(to right, #16c0b0, #84cf6a);
}

.nav_full {
  width: 100%;
  margin-left: $margin-left;
  display: flex;
  align-items: flex-start;
}
@keyframes rainbow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

#nav.mobile {
  background: #fff;
  color: #0a268a;
  height: 7.5rem;
}
#nav_left,
#nav_right {
  margin-top: 2.3rem;
}
#nav_left.mobile,
#nav_right.mobile {
  margin-top: 1.4rem;
}
#submenu {
  top: 15rem;
  position: fixed;
  color: #fff;
  padding-left: 34.4rem;
  height: 5rem;
  background: #0a268a;
  left: 0;
  right: 0;
  z-index: 1;
}
.nav_go_menu::before {
  background-color: #fff;
}
.mobile.nav_go_menu::before {
  background-color: #0a268a;
}

#menu {
  margin-top: 0.8rem;
  // height: 3rem;
  width: 5.5rem;
  cursor: pointer;
}

.left {
  width: 100%;
  max-width: 102.4rem;
  min-width: 70rem;
  text-align: left;
}
.right {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.right.fixed {
  width: auto;
  position: absolute;
  right: 4rem;
}
#logo_container {
  position: relative;
  top: 0px;
}
.logo_100 {
  position: absolute;
  top: 2.6rem;
  left: 5rem;
  width: 15rem;
  z-index: 2;
  user-select: none;
  //  padding: 2rem;
}
.logo_100.small {
  position: absolute;
  top: 1.7rem;
  left: 5rem;
  width: 12rem;
  z-index: 2;

  //  padding: 2rem;
}
#logo_100_bg {
  width: 27.7rem;
  z-index: 2;
}

#logo_100_bg.mobile {
  position: absolute;
  width: 100%;
}
#logo_container.mobile {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  top: 7.5rem;
}
.logo_100.mobile {
  left: auto;
  height: auto;
  width: 50vw;
  position: relative;
  z-index: 1000;
  //  padding: 2rem;
}
.mobile.hide {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
}
.mobile.show {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  visibility: visible;
}
.logo_kla {
  padding-top: 3.6rem;
  padding-right: 4rem;
  padding-left: 2rem;
  padding-bottom: 3.2rem;
  width: 25rem;
  background: white;
  z-index: 1000;
  user-select: none;
  //  padding: 2rem;
}
.logo_kla_bg {
  right: 0;
  top: 0;
  width: 33rem;
  height: 15rem;
  transform: skew(12deg);
  background: white;
  position: absolute;
}
.nav {
  font-weight: 500;
  cursor: pointer;
}
h1.nav {
  text-transform: uppercase;

  font-size: 3.5rem;
  line-height: 4rem;
  margin-bottom: 0;
}
h1.nav.mobile {
  font-size: 2rem;
  line-height: 2.25rem;
}
h2.nav {
  text-transform: uppercase;

  display: inline-block;
  font-size: 1.85rem;
  line-height: 2.75rem;
  padding-right: 2rem;
  margin-top: 1.1rem;
}
h2.lang {
  cursor: default;
  margin-left: 3rem;
}
span.lang {
  opacity: 0.6;
  cursor: pointer;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
span.lang.selected {
  opacity: 1;
}
span.nav {
  display: inline-block;
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 5rem;
  padding-right: 1.25rem;
}

a {
  text-decoration: inherit;
  cursor: default;
}

.router-link-exact-active {
  text-decoration: inherit;
  color: inherit;
}

button,
.button {
  margin-left: auto;
  background: white;
  text-decoration: none;
  color: #2c3e50;

  &.router-link-active {
    text-decoration: inherit;
    color: inherit;
  }
}

.logoutButton {
  cursor: pointer;
}

.nav-welcome + button {
  margin-left: 0;
}
.inactive {
  color: gray;
  cursor: default;
}
#nav.mobile > .nav_full {
  margin-left: 2.25rem;
}
#nav.mobile > .nav_full > .right.fixed {
  right: 2.25rem;
}
#mobileNav {
  position: fixed;
  top: 7.5rem;
  left: 0;
  bottom: 0;
  right: 0;
  background: white;
  z-index: 10;
  padding-left: 2.25rem;
  padding-right: 2.25rem;
  overflow-y: scroll;
}
h1.nav.big {
  font-size: 5.05rem;
  line-height: 4rem;
}
h1.nav.big::before {
  background: $mainBlue;
}
h1.nav.big.nav_go_right {
  padding-left: 5rem;
}
h1.nav.big {
  margin: 4rem 0;
}
.nav_go_l.nav_go_down.big {
  padding-left: 5rem;
}
.nav_go_s.nm {
  margin-left: 5rem;
  display: block;
}
.nav_go_s.nm::before {
  background: $mainBlue;
}
div.nm {
  margin-left: 5rem;
}
span.nav.nm {
  margin: 1rem 0;
  line-height: initial;
}
h2.nm {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
