<template>
  <div v-if="!accepted" class="cookies" :class="{ mobile: mobile }">
    Diese Website benutzt Cookies. Wenn Sie die Website weiter nutzen, gehen wir
    von Ihrem Einverständnis aus. <br />
    Weitere Informationen:
    <a href="https://www.klinik-arlesheim.ch/datenschutz" target="_blank">
      Datenschutz </a
    ><br />
    <span
      class="more uppercase nav nav_go nav_go_s nav_go_right"
      @click="close"
    >
      Hinweis schliessen
    </span>
  </div>
</template>

<script>
export default {
  name: "CookieBanner",
  computed: {
    mobile() {
      return this.$store.getters.mobile;
    },
    accepted() {
      return this.$store.getters.acceptedCookies;
    }
  },
  methods: {
    close() {
      this.$store.commit("acceptCookies");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/_variables.scss";

.cookies {
  font-size: 2rem;
  text-align: center;
  padding: 3rem 0rem;
  width: 100%;
  height: 9rem;
  position: fixed;
  bottom: 0;
  background: rgb(175, 35, 115);
  background: linear-gradient(
    90deg,
    rgba(175, 35, 115, 0.9) 0%,
    rgba(235, 122, 20, 0.9) 33.333%,
    rgba(185, 185, 85, 0.9) 66.666%,
    rgba(10, 38, 138, 0.9) 100%
  );
  background-size: 125% 125%;

  color: #fff;
  animation: rainbow 18s ease-in-out infinite;
  z-index: 10;
}
.cookies.mobile {
  font-size: 2rem;
  height: 15rem;
}
@keyframes rainbow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
a {
  text-decoration: underline;
}
span.more {
  margin: 2rem 0;
}
span.more::before {
  background: white;
}
</style>
