<template>
  <div>
    <router-link to="/events">
      <span class="more uppercase nav nav_go nav_go_s nav_go_right"
        >Vorschau</span
      >
    </router-link>
    <div id="new">
      <div class="item button " @click="editEvent(0, $event)">
        <span class="more uppercase nav nav_go nav_go_s nav_go_right">
          neuer Event erstellen</span
        >
      </div>
    </div>
    <div v-if="eventReady">
      <div
        v-for="event in events"
        :key="event.id"
        class="click"
        @click="editEvent(event.id, $event)"
      >
        <Event :key="event.id" :event="event" />
      </div>
    </div>
  </div>
</template>

<script>
import Event from "../components/EventPreview";

export default {
  components: {
    Event
  },
  data() {
    return {
      data: []
    };
  },
  computed: {
    eventReady: function() {
      return this.$store.getters.eventsLoaded;
    },
    eventLoading() {
      return this.$store.getters.eventsLoading;
    },
    events() {
      return this.$store.getters.events;
    }
  },
  created() {
    if (!this.eventReady && !this.eventLoading) {
      this.$store.dispatch("loadEvents");
    }
    if (!this.storyReady && !this.storyLoading) {
      this.$store.dispatch("loadStory");
    }
  },
  methods: {
    editEvent: function(id, e) {
      e.preventDefault();
      e.stopPropagation();
      this.$router.push({
        name: "editEvent",
        params: {
          id: id
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../scss/_variables.scss";
@import "../scss/_admin.scss";
#new {
  margin-bottom: 2rem;
}
.more {
  color: white;
  margin-left: 2rem;
}
.more::before {
  background: white;
}
</style>
