<template>
  <div class="admin_center p">
    <div>
      <div class="item">
        <form>
          <label for="email">
            E-Mail:
          </label>
          <input v-model="email" type="email" name="email" value />
        </form>
      </div>
      <div class="item button mainb" @click="resetRequest">
        <span class="more uppercase nav nav_go nav_go_s nav_go_right"
          >Passwort zurücksetzen</span
        >
      </div>
      <p>{{ error }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      error: null
    };
  },
  methods: {
    resetRequest() {
      this.$store
        .dispatch("resetRequest", {
          email: this.email
        })
        .then(() => {
          this.$router.push({ name: "login" });
        })
        .catch(err => {
          this.error = err.error;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/_variables.scss";
@import "../scss/admin.scss";

input {
  width: 30rem;
}

.more {
  margin: 1rem 0;
}
</style>
