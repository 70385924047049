import { render } from "./BaseCheckbox.vue?vue&type=template&id=157c8038"
import script from "./BaseCheckbox.vue?vue&type=script&lang=js"
export * from "./BaseCheckbox.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "157c8038"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('157c8038', script)) {
    api.reload('157c8038', script)
  }
  
  module.hot.accept("./BaseCheckbox.vue?vue&type=template&id=157c8038", () => {
    api.rerender('157c8038', render)
  })

}

script.__file = "src/components/BaseCheckbox.vue"

export default script