import { render } from "./EventPreview.vue?vue&type=template&id=25c27604&scoped=true"
import script from "./EventPreview.vue?vue&type=script&lang=js"
export * from "./EventPreview.vue?vue&type=script&lang=js"

import "./EventPreview.vue?vue&type=style&index=0&id=25c27604&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-25c27604"
/* hot reload */
if (module.hot) {
  script.__hmrId = "25c27604"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('25c27604', script)) {
    api.reload('25c27604', script)
  }
  
  module.hot.accept("./EventPreview.vue?vue&type=template&id=25c27604&scoped=true", () => {
    api.rerender('25c27604', render)
  })

}

script.__file = "src/components/EventPreview.vue"

export default script