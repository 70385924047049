import { render } from "./Contributors.vue?vue&type=template&id=3b151328&scoped=true"
import script from "./Contributors.vue?vue&type=script&lang=js"
export * from "./Contributors.vue?vue&type=script&lang=js"

import "./Contributors.vue?vue&type=style&index=0&id=3b151328&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-3b151328"
/* hot reload */
if (module.hot) {
  script.__hmrId = "3b151328"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('3b151328', script)) {
    api.reload('3b151328', script)
  }
  
  module.hot.accept("./Contributors.vue?vue&type=template&id=3b151328&scoped=true", () => {
    api.rerender('3b151328', render)
  })

}

script.__file = "src/components/Contributors.vue"

export default script