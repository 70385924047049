import { createApp, h } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./vuex/store";
import axios from "axios";
import moment from "moment";
import VueGtag from "vue-gtag-next";

import "moment/locale/de"; // without this line it didn't work
moment.locale("de");
//import ScrollAnimation from "./directives/ScrollAnimations";

const app = createApp({
  created() {
    const userString = localStorage.getItem("user");
    if (userString) {
      const userData = JSON.parse(userString);
      this.$store.commit("SET_USER_DATA", userData);
    }
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
          if (this.$route.name != "login") {
            this.$store.dispatch("logout");
          }
        }
        return Promise.reject(error);
      }
    );
  },

  render: () => h(App)
})
  .use(router)
  .use(store)
  .use(moment)
  .use(VueGtag, {
    property: {
      id: "G-BW9J21ZLGG"
    }
  });

app.mount("#app");
//.mount("#app");
