<template>
  <div v-if="ready" class="page">
    <teleport to="head">
      <meta property="og:title" :content="firstEvent.title" />
      <meta
        name="description"
        property="og:description"
        :content="firstEvent.text"
      />
      <meta property="og:image:url" content="../assets/default.jpg" />
    </teleport>
    <div
      v-for="(month, key) in months"
      :key="key"
      class="months"
      :class="{ mobile: small }"
    >
      <div :id="monthName(month)" class="anchor"></div>
      <div class="month_title" :class="{ mobile: mobile }">
        <div :class="{ rotate: !small }">
          <span
            class="uppercase month_title"
            :class="{ mobile: mobile, small: small }"
            >{{ monthName(month) }}</span
          >
        </div>
      </div>

      <div class="events" :class="{ offset: !mobile }">
        <Event
          v-for="event in eventsByMonth(month)"
          :key="event.id"
          :event="event"
        />
      </div>
      <div
        v-if="
          secondColumn &&
            hasLinkToStory(month) &&
            eventsByMonth(month).length > 1
        "
      >
        <LinkToStory :story="linkToStory(month)" />
      </div>
    </div>
    <div id="page_end"></div>
  </div>
</template>

<script>
//import axios from "axios";
import Event from "../components/EventPreview";
import LinkToStory from "../components/LinkToStory";

export default {
  components: { Event, LinkToStory },
  props: {
    hidefirst: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      archive: false
    };
  },
  computed: {
    secondColumn() {
      return this.$store.getters.secondColumn;
    },
    mobile() {
      return this.$store.getters.mobile;
    },
    small() {
      return this.$store.getters.break1;
    },
    loading() {
      return this.$store.getters.eventsLoading;
    },
    ready() {
      return this.$store.getters.eventsLoaded;
    },
    storyLoading() {
      return this.$store.getters.storyLoading;
    },
    storyReady() {
      return this.$store.getters.storyLoaded;
    },
    firstEvent() {
      return this.$store.getters["FirstEvent"];
    },
    months() {
      let _m = [];
      this.$store.getters.months.filter((month, key) => {
        let k = key + 1;
        if (this.$store.getters["eventsByMonths"](k).length > 0) {
          _m.push(k);
          return true;
        }
      });
      return _m;
    },
    loggedIn() {
      return this.$store.getters["loggedIn"];
    }
  },
  created() {
    if ((!this.ready && !this.loading) || this.loggedIn) {
      this.$store.dispatch("loadEvents").then(() => {
        if (this.$route.hash) {
          setTimeout(() => this.scrollTo(this.$route.hash), 1);
        }
      });
    }
    if ((!this.storyReady && !this.storyLoading) || this.loggedIn) {
      this.$store.dispatch("loadStory");
    }
  },
  mounted() {
    this.$store.commit("hideEvents", this.hidefirst);
    this.$store.commit("showArchive", this.$route.name == "archive");
    const self = this;
    if (this.$route.hash && this.ready) {
      setTimeout(() => this.scrollTo(self.$route.hash), 1);
    }
  },
  methods: {
    eventsByMonth(m) {
      return this.$store.getters["eventsByMonths"](m);
    },
    monthName(n) {
      n = n - 1;
      return this.$store.getters["months"][n];
    },
    hasLinkToStory(m) {
      return (
        this.$store.getters.story.filter(story => story.month == m).length > 0
      );
    },
    linkToStory(m) {
      return this.$store.getters.story.filter(story => story.month == m)[0];
    },
    scrollTo: function(hashtag) {
      setTimeout(() => {
        location.href = hashtag;
      }, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/_variables.scss";

.months.mobile {
  flex-direction: column;
}
.months:nth-child(3n + 1) {
  background: $bgLila;
}
.months:nth-child(3n) {
  background: $bgGreen;
}
.months:nth-child(3n + 2) {
  background: $bgOrange;
}

div.month_title.mobile {
  padding-bottom: 3rem;
  margin-left: 2.25rem;
}

span.month_title.mobile {
  /*  position: absolute;*/
  font-size: 3.5rem;
  line-height: 3rem;
}
span.month_title.small {
  /*  position: absolute;*/
  line-height: 9rem;
}
.events {
  width: 100%;
}
.offset {
  transform: translateY(-1.5rem);
}
.page {
  padding-top: 3rem;
  background: $bgLila;
}
.months {
  position: relative;
}

#page_end {
  width: 100%;
  height: 40vh;
}
</style>
