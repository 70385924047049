<template>
  <div>
    <div class="admin_center p">
      <div>
        <div class="item">
          <form>
            <label for="password">
              Passwort:
            </label>
            <input v-model="password" type="password" name="password" value />
            <label for="password">
              Passwort wiederholen:
            </label>
            <input
              v-model="password_confirmation"
              type="password"
              name="password_confirmation"
              value
            />
          </form>
        </div>
        <div class="item button mainb" @click="resetRequest">
          <span class="more uppercase nav nav_go nav_go_s nav_go_right">
            Passwort ändern</span
          >
        </div>
      </div>
    </div>

    <p>{{ error }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: this.$route.query.email,
      token: this.$route.query.token,
      error: null,
      password: null,
      password_confirmation: null
    };
  },
  methods: {
    resetRequest() {
      this.$store
        .dispatch("resetPassword", {
          password: this.password,
          password_confirmation: this.password_confirmation,
          email: this.email,
          token: this.token
        })
        .then(() => {
          this.$router.push({ name: "dashboard" });
        })
        .catch(err => {
          this.error = err.response.data.error;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/_variables.scss";
@import "../scss/admin.scss";

.login {
}
input {
  width: 30rem;
}

.more {
  margin: 1rem 0;
}
</style>
