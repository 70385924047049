<template>
  <div class="main" :class="{ mobile: mobile }">
    <AdminNav v-if="loggedIn" />
    <AppNavi v-else />
    <div class="marge" :class="{ mobile: mobile }"></div>
    <router-view />
  </div>

  <div id="bottom" :class="{ mobile: mobile }">
    <div class="i">
      Klinik Arlesheim <br />
      Pfeffingerweg 1 <br />
      CH-4144 Arlesheim
    </div>
    <div class="i i_m">
      <a
        href="https://www.klinik-arlesheim.ch/agb"
        target="_blank"
        rel="noreferrer"
        >AGB</a
      >
      <br />
    </div>
    <div class="i i_r">
      Zentrale <br />
      Tel. +41 (0)61 705 71 11 <br />
      <a href="mailto:info@klinik-arlesheim.ch">info@klinik-arlesheim.ch</a>
      <br />
    </div>
  </div>
  <CookieBanner />
</template>

<script>
import AppNavi from "./components/AppNavi";
import AdminNav from "./components/AdminNav";
import CookieBanner from "./components/CookieBanner";
import { authComputed } from "./vuex/helpers.js";

export default {
  components: { AdminNav, AppNavi, CookieBanner },
  data() {
    return {
      baseSize: {
        fontSize: this.calculateBaseSize() + "px"
      }
    };
  },
  computed: {
    ...authComputed,
    mobile() {
      return this.$store.getters.mobile;
    },
    lang() {
      return this.$route.params.lang;
    }
  },
  created() {
    //  if (this.$route.params.lang == 'en') {
    //  }
  },
  mounted() {
    window.addEventListener("resize", this.changeBaseSize);
    this.changeBaseSize();
  },

  methods: {
    calculateBaseSize() {
      if (window.innerWidth === 1440) {
        return 8; //8.8
      } else {
        if (window.innerWidth < 800) {
          return 10;
        } else {
          return window.innerWidth >= 990
            ? (window.innerWidth - (window.innerWidth - 1440) * 0.75) * 0.005555 //(window.innerWidth - 1440) * 0.75) * 0.006026
            : 7.35; //8
        }
      }
    },
    changeBaseSize() {
      this.$store.commit("setWindowSize", window.innerWidth);
      var d = document.documentElement;
      d.style.fontSize = this.calculateBaseSize() + "px";
      //    this.baseSize.fontSize = this.calculateBaseSize() + "px";
    }
  }
};
</script>

<style lang="scss">
@import "./assets/styles/global.scss";
@import "./scss/_variables.scss";
.page {
  /*  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;*/
  min-height: calc(100vh - 15rem);
}
.main {
  min-height: calc(100vh - 15rem);
}
.main.mobile {
  min-height: calc(100vh - 7.5rem);
}
.marge {
  width: 100%;
  height: 15rem;
}
.marge.mobile {
  height: 7.5rem;
}

form {
  display: block;
  width: 39rem;
}
input {
  padding: 0.5em;
}
input,
select {
  display: block;
  color: $mainBlue;
  font-size: 2rem;
  height: 4rem;
  width: 100%;
  // max-width: 39rem;
  margin-bottom: 1rem;

  background: none;
  border: solid;
  box-sizing: border-box;
  border-width: 2px;
  border-color: $mainBlue;
}
select {
  outline: none;
  position: relative;
  display: block;
  width: 10.5rem;
  padding-left: 2.5rem;
}
select::after {
  position: absolute;
  right: 0;
  top: 0;
  width: 4rem;
  height: 100%;
  content: "&";
  text-align: center;
  color: $mainBlue;
  font-size: 2rem;
  border-left: 2px solid $mainBlue;
}
form {
  margin-top: 4rem;
}

.bg1 {
  background: $bgGreen;
}
.bg2 {
  background: $bgOrange;
}
.bg3 {
  background: $bgLila;
}
.bg1.reverse {
  background: $bgGreen_reverse;
}
.bg2.reverse {
  background: $bgOrange_reverse;
}
.bg3.reverse {
  background: $bgLila_reverse;
}
#bottom {
  display: flex;
  position: absolute;
  height: 15rem;
  width: 100%;
  background: $mainBlue;
  z-index: 4;
}
#bottom.mobile > .i {
  font-size: 1.4rem;
}
.i {
  padding: 2rem;
  color: white;
  font-size: 2rem;
  width: 25rem;
}
.i > a {
  text-decoration: underline;
}
.i_m {
}
.i_r {
}
.overlay {
  top: 15rem;
  position: fixed;
  height: calc(100% - 15rem);
  width: 100%;
  background: $rainbow_0;
  z-index: 100;
}
.overlay.mobile {
  top: 7.5rem;
  height: calc(100% - 7.5rem);
}
</style>
